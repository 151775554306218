// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// There are different entries for each API, in case we want to
// migrate the calls to the APIs one by one.

export const environment = {
  production: false,
  test: true,
  sandbox: true,
  salesforceUrl: "https://enpalgmbh2--planning.sandbox.my.salesforce.com",
  sunroofUrl: "https://sunroof-sandbox.craftos.enpal.tech",
  ipaUrl: "https://buildability.craftos.enpal.tech",
  frontend: {
    loadConfigURL: "/load",
  },
  auth: {
    b2cAppClientId: "47581a4c-d2b1-4694-9d05-681427ad1f1c",
  },
  backend: {
    apiKey: "9aRzJrzlNcDjnzfapPaDqDi6-PLUVfdonNG_VRbWoEgyAzFuw24qJg==",
    api: {
      projectsUrl:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/projects",
      projectUrl:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/project",
      opportunityURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/salesforce/opportunity",
      addressURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/engine/address",
      sideLengthURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/engine/sidelength",
      areaCornersURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/engine/corners",
      imageUploadURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/images/upload",
      imageSasURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/images/sas",
      placePlanelsURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/engine/place-panels",
      groundCornersURL:
        "https://func-sope-api-sandbox.azurewebsites.net/api/v1/engine/ground-corners",

      // opportunityURL:   "http://localhost:7071/api/v1/salesforce/opportunity",
      // addressURL:       "http://localhost:7071/api/v1/engine/address",
      // sideLengthURL:    "http://localhost:7071/api/v1/engine/sidelength",
      // areaCornersURL:   "http://localhost:7071/api/v1/engine/corners",
      // imageUploadURL:   "http://localhost:7071/api/v1/images/upload",
      // placePlanelsURL:  "http://localhost:7071/api/v1/engine/place-panels",
      // groundCornersURL: "http://localhost:7071/api/v1/engine/ground-corners",

      satelliteVizURL: "http://localhost:3000/api/satellite-visualization",
      savePlacementURL: "http://localhost:3000/api/save-config",
      loadConfigURL: "http://localhost:3000/api/load-config",
      authURL: "https://offergen.enpal.de/",
    },
  },
  map: {
    MAPS_API_KEY: "AIzaSyBKCTwTmBFpqj3AnRdPOYY-jyVPo5L9e0U",
    DEFAULT_ZOOM_LEVEL: 20,
    DEFAULT_MAP_SCALE: 1,
    DEFAULT_MAP_IMAGE_SIZE: "640x480",
  },
  bingMaps: {
    API_KEY: "Ap0AObt84NcDaUThCeWOj52ZqUHv6k4TJhjLibR-DghC-semgoj-0uPbIi8r0E4j",
  },
  panels: {
    DEFAULT_PANEL_WIDTH: 100,
    DEFAULT_PANEL_HEIGHT: 170,
  },
};
