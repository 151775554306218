import { PlanningDataService } from "../core/services/planning-data.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import { Component, ElementRef, NgZone, OnInit } from "@angular/core";
import _forEach from "lodash/forEach";
import { MsalService } from "@azure/msal-angular";
import { Project } from "../core/models/projectModel";
import { ModalService } from "../core/modal/modal.service";
import { environment as env } from "../../environments/environment";
import { ProjectResolver } from "../core/projectResolver";

@Component({
  styleUrls: ["./projects-page.component.css"],
  selector: "cm-projects-page",
  templateUrl: "./projects-page.component.html",
})
export class ProjectsPageComponent implements OnInit {
  orderNumber: string;
  projects: Project[] = [];
  opportunity: any;
  linkToSalesforce: string;
  opportunityNotFound: boolean;

  constructor(
    private elRef: ElementRef,
    private route: ActivatedRoute,
    private router: Router,
    private authService: MsalService,
    private planningDataService: PlanningDataService,
    private modalService: ModalService
  ) {}

  async ngOnInit() {
    ProjectResolver.reset();
    this.orderNumber = this.route.snapshot.paramMap.get("orderNumber");
    try {
      this.opportunity =
        await this.planningDataService.getSalesforceOpportunity(
          this.orderNumber
        );
    } catch (e) {
      if (e.status === 404) {
        this.opportunityNotFound = true;
      } else {
        alert(`An error has occurred: ${e.message}`);
      }
      return;
    }

    try {
      this.projects = await this.planningDataService.getProjectsByOrderNumber(
        this.orderNumber
      );
      this.linkToSalesforce = `${env.salesforceUrl}/${this.opportunity.id}`;
    } catch (e) {
      alert(`An error has occurred: ${e.message}`);
    }
  }

  async onCreateProject() {
    try {
      const newProject: Project = await this.planningDataService.createProject(
        this.orderNumber,
        ""
      );
      this.router.navigate(["/projects", this.orderNumber, newProject.id], {
        queryParams: { mode: "create" },
      });
    } catch (e) {
      alert(`An error has occurred: ${e.message}`);
    }
  }

  openProject(project: Project) {
    // If there was at least one finished roof, open it on the panels placement page
    if (project.roofs.findIndex((r) => r.numPlacedPanels > 0) >= 0) {
      this.router.navigate(
        ["/projects", project.orderNumber, project.id, "panel-placing"],
        { queryParams: { mode: "edit" } }
      );
    } else {
      this.router.navigate(["/projects", project.orderNumber, project.id], {
        queryParams: { mode: "create" },
      });
    }
  }

  getNumberOfPanels(project: Project) {
    let moduleCount = 0;
    project.roofs.forEach((roof) => (moduleCount += roof.numPlacedPanels));
    return moduleCount;
  }

  parseDate(date: string) {
    if (date && date !== "") {
      return new Date(date).toLocaleString([], {
        day: "numeric",
        month: "numeric",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
      });
    }
    return "";
  }

  ngOnDestroy() {}
}
