import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AuthService } from "../core/services/auth.service";
import { ValidationService } from "../core/services/validation.service";
import { IUserLogin } from "../shared/interfaces";
import {
  GrowlerService,
  GrowlerMessageType,
} from "../core/growler/growler.service";

@Component({
  selector: "cm-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  errorMessage: string;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private growler: GrowlerService
  ) {}

  loading: boolean = false;
  returnUrl: string;

  ngOnInit() {
    this.buildForm();

    // get return url from route parameters or default to '/'
    this.returnUrl =
      this.route.snapshot.queryParams["returnUrl"] || "/packages?client=1";
  }

  buildForm() {
    this.loginForm = this.formBuilder.group({
      email: [
        "mario.kohle@enpal.de",
        [Validators.required, ValidationService.emailValidator],
      ],
      password: [
        "1mario.kohle@enpal.de",
        [Validators.required, ValidationService.passwordValidator],
      ],
    });
  }

  submit({ value, valid }: { value: IUserLogin; valid: boolean }) {
    this.authService.login(value.email, value.password).subscribe(
      (status: boolean) => {
        if (status) {
          this.growler.growl("Logged in", GrowlerMessageType.Info);
          if (this.returnUrl) {
            const redirectUrl = this.returnUrl;
            this.router.navigate([redirectUrl]);
          } else {
            this.router.navigate(["/packages?client=3"]);
          }
        } else {
          const loginError = "Unable to login";
          this.errorMessage = loginError;
          this.growler.growl(loginError, GrowlerMessageType.Danger);
        }
      },
      (err: any) => console.log(err)
    );
  }
}
