import { ADDED_SPACE } from "./../../shared/constants";
import { checkIfCornersChanged } from "../../shared/utils";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment as env } from "../../../environments/environment";
import * as constants from "../../shared/constants";
import _cloneDeep from "lodash/cloneDeep";
import _filter from "lodash/filter";
import _find from "lodash/find";
import _forEach from "lodash/forEach";
import _join from "lodash/join";
import { ActivatedRoute } from "@angular/router";
import { Project, ProjectRoof } from "../models/projectModel";
import { catchError, map } from "rxjs/operators";
import { isNumber } from "util";
import {} from "googlemaps";
import { ImageResolver } from "./image-resolver";

@Injectable()
export class PlanningDataService {
  onSpinnerChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onComponentVisibilityChanged: BehaviorSubject<any> = new BehaviorSubject({});
  imageResolver: ImageResolver;

  constructor(private http: HttpClient, private route: ActivatedRoute) {
    this.imageResolver = new ImageResolver((uris: string[]) =>
      this.resolveImageUris(uris)
    );
  }

  BING_GEOCODING_URL: string = `https://dev.virtualearth.net/REST/v1/Locations`;
  GOOGLE_GEOCODING_URL: string = `https://dev.virtualearth.net/REST/v1/Locations`;
  ADDRESS_ENDPOINT_URL: string =
    env.backend.api.addressURL + "?code=" + env.backend.apiKey;
  AREA_CORNER_DETECTION_ENDPOINT_URL: string =
    env.backend.api.areaCornersURL + "?code=" + env.backend.apiKey;
  IMAGE_UPLOAD_ENDPOINT_URL: string =
    env.backend.api.imageUploadURL + "?code=" + env.backend.apiKey;
  GROUND_CORNER_DETECTION_ENDPOINT_URL: string =
    env.backend.api.groundCornersURL + "?code=" + env.backend.apiKey;
  PLACE_PANELS_ENDPOINT_URL: string =
    env.backend.api.placePlanelsURL + "?code=" + env.backend.apiKey;
  PLACE_PANELS_ON_SATELLITE_URL: string =
    env.backend.api.satelliteVizURL + "?code=" + env.backend.apiKey;
  SAVE_CONFIG_ENDPOINT_URL: string =
    env.backend.api.savePlacementURL + "?code=" + env.backend.apiKey;
  GRID_ENDPOINT_URL: string =
    env.backend.api.placePlanelsURL + "?code=" + env.backend.apiKey;
  SATELLITE_GRID_ENDPOINT_URL: string =
    env.backend.api.satelliteVizURL + "?code=" + env.backend.apiKey;
  SIDE_LENGTH_ENDPOINT_URL: string =
    env.backend.api.sideLengthURL + "?code=" + env.backend.apiKey;
  LOAD_CONFIG_ENDPOINT_URL: string =
    env.backend.api.loadConfigURL + "?code=" + env.backend.apiKey;
  LOAD_CONFIG_FRONTEND_URL: string = env.frontend.loadConfigURL;

  addressCenterChanged: Subject<any> = new Subject();
  onUserImageChanged: BehaviorSubject<any> = new BehaviorSubject({});
  onGroundImageCornersChanged: Subject<any> = new Subject();
  onSideLengthChanged: Subject<any> = new Subject();

  visibilityMap: any = {
    MAP_SELECTION: true,
    DISPLAY_AREA: false,
    AREA_CORNERS_SELECTION: false,
    SATELLITE_VISUALIZATION: false,
    IMAGE_UPLOAD: false,
    USER_IMAGE_CORNERS_SELECTION: false,
    PANEL_PLACING: false,
    TEST_SATELLITE_GRID: false,
    TEST_GROUND_GRID: false,
  };

  setComponentVisibility(componentId, isComponentVisible) {
    _forEach(Object.keys(this.visibilityMap), (key) => {
      if (key === componentId) {
        this.visibilityMap[key] = isComponentVisible;
      } else {
        this.visibilityMap[key] = !isComponentVisible;
      }
    });

    this.onComponentVisibilityChanged.next(this.visibilityMap);
    localStorage.setItem(
      constants.LOCAL_STORAGE_KEYS.VISIBILITY_MAP,
      JSON.stringify(this.visibilityMap)
    );
  }

  async getProject(orderNumber: string, projectId: string): Promise<any> {
    const url: string = `${env.backend.api.projectUrl}/${orderNumber}/${projectId}?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    const project = (await this.http.get(url, options).toPromise()) as Project;

    const imageUriPaths = getImagePaths(project);
    await this.imageResolver.resolveImageUris(project, imageUriPaths);

    return project;
  }

  async resolveImageUris(uris: string[]): Promise<any> {
    const url: string = `${env.backend.api.imageSasURL}?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    const { uris: resolvedUris } = (await this.http
      .post(url, { uris }, options)
      .toPromise()) as any;
    return resolvedUris;
  }

  getProjectsByOrderNumber(orderNumber: string): Promise<any> {
    const url: string = `${env.backend.api.projectsUrl}/${orderNumber}?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    return this.http.get(url, options).toPromise();
  }

  createProject(orderNumber: string, description: string): Promise<any> {
    const url: string = `${env.backend.api.projectUrl}/${orderNumber}?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    return this.http.post(url, description, options).toPromise();
  }

  getSalesforceOpportunity(orderNumber: string): Promise<any> {
    const url: string = `${env.backend.api.opportunityURL}/${orderNumber}?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    return this.http.get(url, options).toPromise();
  }

  postPlanningImageToSalesforce(
    orderNumber: string,
    image: Blob,
    fileName: string
  ): Promise<any> {
    const url: string = `${env.backend.api.opportunityURL}/${orderNumber}/fileupload?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    var fd = new FormData();
    fd.append("file", image, fileName);

    return this.http.post(url, fd, options).toPromise();
  }

  postPlanningImageToSalesforceWithCategory(
    orderNumber: string,
    image: Blob,
    fileName: string,
    category: string
  ): Promise<any> {
    const url: string = `${env.backend.api.opportunityURL}/${orderNumber}/fileupload?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    var fd = new FormData();
    fd.append("file", image, fileName);
    fd.append("Category", category);

    return this.http.post(url, fd, options).toPromise();
  }

  postProjectPlannedImage(
    orderNumber: string,
    projectId: string,
    roofIndex: number,
    image: Blob,
    fileName: string,
    category: string
  ): Promise<any> {
    const url: string = `${env.backend.api.projectsUrl}/${orderNumber}/${projectId}/${roofIndex}/planned-images?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "multipart/form-data");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    var fd = new FormData();
    fd.append("file", image, fileName);
    fd.append("Category", category);

    return this.http.post(url, fd, options).toPromise();
  }

  persist(project: Project): Promise<any> {
    const persistableProject = _cloneDeep(project);
    const imageUriPaths = getImagePaths(project);
    this.imageResolver.cleanResolvableUris(persistableProject, imageUriPaths);

    const url: string = `${env.backend.api.projectUrl}/${project.orderNumber}/${project.id}?code=${env.backend.apiKey}`;
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };
    return this.http.put(url, persistableProject, options).toPromise();
  }

  setAddressCenter(center) {
    this.addressCenterChanged.next(center);
  }

  getStaticMapImage(project: Project) {
    var center = project.addressCenter.center;
    var config = project.addressCenter.config;

    var zoom = env.map.DEFAULT_ZOOM_LEVEL;
    var scale = env.map.DEFAULT_MAP_SCALE;
    var size = env.map.DEFAULT_MAP_IMAGE_SIZE;

    project.addressCenter.config = {
      zoom: zoom,
      scale: scale,
      size: size,
    };

    project.addressCenter.center = center;

    // Save the center
    if (config) {
      if (config.zoom) {
        zoom = config.zoom;
        project.addressCenter.config.zoom = zoom;
      }

      if (config.scale) {
        scale = config.scale;
        project.addressCenter.config.scale = scale;
      }

      if (config.size) {
        size = config.size;
        project.addressCenter.config.size = size;
      }
    }

    this.setAddressCenter(project.addressCenter);

    return `https://maps.googleapis.com/maps/api/staticmap?center=${project.addressCenter.center.latitude},${project.addressCenter.center.longitude}&zoom=20&size=${env.map.DEFAULT_MAP_IMAGE_SIZE}&maptype=satellite&key=${env.map.MAPS_API_KEY}`;
  }

  geocode(query, service?): Promise<google.maps.GeocoderGeometry> {
    return new Promise((resolve, reject) => {
      const geocoder = new google.maps.Geocoder();
      geocoder.geocode({ address: query }, (results, status) => {
        if (status == "OK") {
          return resolve(results[0].geometry);
        }
        return reject(status);
      });
    });
  }

  submitAddressInfo(center, config?): Promise<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);

    var centerModel = {
      center: center,
    };

    if (config) {
      centerModel["config"] = config;
    }

    this.setAddressCenter(centerModel);
    const endpointUrl =
      this.ADDRESS_ENDPOINT_URL +
      "&lat=" +
      center.latitude +
      "&lng=" +
      center.longitude +
      "&zoom=20";

    return this.http.get(endpointUrl).toPromise();
  }

  detectAreasCorners(roof: ProjectRoof): Promise<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers: headers };
    const cornerDetectionInfo = this._createCornerDetectionInfo(roof);

    return this.http
      .post(
        this.AREA_CORNER_DETECTION_ENDPOINT_URL,
        cornerDetectionInfo,
        options
      )
      .toPromise();
  }

  _createCornerDetectionInfo(roof: ProjectRoof) {
    const selectedAreas = roof.selectedAreas;

    // Find the other areas in the same instance as the selected area
    const areasInSameInstance = this.findAllAreasInInstance(
      roof,
      selectedAreas[0].instance
    );

    let selectedAreasModel: any = {
      points: areasInSameInstance,
      roofPitch: roof.roofPitch,
    };

    const instanceChanged = selectedAreas[0].modified;

    if (!instanceChanged) {
      const selectedInstance = areasInSameInstance[0].instance;
      const model = {
        points: areasInSameInstance,
        selectedInstance: selectedInstance,
        roofPitch: roof.roofPitch,
      };
      selectedAreasModel = model;
    }

    selectedAreasModel["modified"] = instanceChanged;

    var data = {
      finalRegion: selectedAreasModel,
    };

    return data;
  }

  setSideLength(sideLength) {
    this.onSideLengthChanged.next(sideLength);
  }

  async uploadImage(
    fileName: string,
    orderNumber: string,
    blob: any
  ): Promise<string> {
    const form = new FormData();
    form.append("image", blob, fileName);

    // Post the form to the endpoint
    const url: string = `${env.backend.api.imageUploadURL}/${orderNumber}?code=${env.backend.apiKey}`;

    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers };

    const response = await this.http.post(url, form, options).toPromise();

    const newImg: any = response;
    return await this.imageResolver.resolveImageUri(newImg.imageUrl);
  }

  uploadUserImage(): Promise<any> {
    return this._uploadImage();
  }

  _uploadImage(): Promise<any> {
    let headers = new HttpHeaders();

    // FIXME: Change content-type header to Multipart
    //headers.append('Content-Type', 'application/json; charset=utf-8');
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers: headers };
    const imageData = "";

    return this.http
      .post(this.IMAGE_UPLOAD_ENDPOINT_URL, imageData, options)
      .toPromise();
  }

  setUserImageUrl(imageUrl) {
    this.onUserImageChanged.next(imageUrl);
  }

  detectGroundImageCorners(roof: ProjectRoof): Promise<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    var options = { headers: headers };

    const groundCornerDetectionInfo = {
      imageUrl: roof.userImageUrl,
      added_space: ADDED_SPACE,
    };

    return this.http
      .post(
        this.GROUND_CORNER_DETECTION_ENDPOINT_URL,
        groundCornerDetectionInfo,
        options
      )
      .toPromise();
  }

  getFinalRegionInfo(roof: ProjectRoof) {
    var selectedAreas = roof.selectedAreas;

    var initialAreasCorners = roof.initialAreasCorners;
    var selectedAreasCorners = roof.selectedAreasCorners;
    var areaCornersModified = checkIfCornersChanged(
      initialAreasCorners,
      selectedAreasCorners
    );

    // Find the other areas in the same instance as the selected area
    var areasInSameInstance = this.findAllAreasInInstance(
      roof,
      selectedAreas[0].instance
    );
    var corners = this.transformCorners(selectedAreasCorners);
    var selectedAreasModel: any = {
      points: areasInSameInstance,
      corners: corners,
      cornersModified: areaCornersModified, //,
      // roofPitch: this.roofPitch
    };

    var instanceChanged = selectedAreas[0].modified;

    if (!instanceChanged) {
      var selectedInstance = areasInSameInstance[0].instance;
      var model = {
        points: areasInSameInstance,
        selectedInstance: selectedInstance,
        corners: corners,
        cornersModified: areaCornersModified, //,
        //roofPitch: this.roofPitch
      };
      selectedAreasModel = model;
    }

    selectedAreasModel["modified"] = instanceChanged;

    return selectedAreasModel;
  }

  submitInfoForPlacementOnUserImage(roof: ProjectRoof): Promise<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers: headers };
    const placementInfo = this._createPanelPlacementInfo(roof);

    return this.http
      .post(this.PLACE_PANELS_ENDPOINT_URL, placementInfo, options)
      .toPromise();
  }

  updateSideLength(
    latitude: number,
    longitude: number,
    roof: ProjectRoof
  ): Promise<any> {
    const headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    const options = { headers: headers };
    const requestData = {
      satellite_corners: roof.selectedAreasCorners.map((c) => [c.x, c.y]),
      latitude,
      longitude,
    };

    return this.http
      .post(this.SIDE_LENGTH_ENDPOINT_URL, requestData, options)
      .toPromise();
  }

  submitInfoForPlacementOnSatellite(roof: ProjectRoof): Promise<any> {
    let headers = new HttpHeaders();
    headers.append("Content-Type", "application/json; charset=utf-8");
    headers.append("X-API-KEY", env.backend.apiKey);
    var options = { headers: headers };

    let placementInfo = this._createPanelPlacementInfo(roof, "SATELLITE");

    return this.http
      .post(this.PLACE_PANELS_ON_SATELLITE_URL, placementInfo, options)
      .toPromise();
  }

  findAllAreasInInstance(roof: ProjectRoof, instanceIndex: number) {
    let areasInInstance = [];
    _forEach(roof.areas, (instanceAreas, index) => {
      if (index + 1 === instanceIndex) {
        // Instances start from 1 in the backend
        areasInInstance = instanceAreas;
      }
    });

    return areasInInstance;
  }

  private _createPanelPlacementInfo(roof: ProjectRoof, type?) {
    if (type !== "SATELLITE") {
      type = "GROUND_IMAGE";
    }

    var selectedAreasCorners = roof.selectedAreasCorners;
    var corners = this.transformCorners(selectedAreasCorners);
    var roofPitch = roof.roofPitch;
    if (!roofPitch) {
      roofPitch = 40;
    }

    const zoomLevel = 20;
    var data = {
      orientation: roof.panelsOrientation,
      zoom: zoomLevel,
      panelDimensions: {
        width: env.panels.DEFAULT_PANEL_WIDTH,
        height: env.panels.DEFAULT_PANEL_HEIGHT,
      },
    };

    if (roof.sideLength) {
      var sideLengthModified = roof.initialSideLength != roof.sideLength;
      data["sideLengthMeters"] = roof.sideLength;
      data["sideLengthModified"] = sideLengthModified;
    }

    if (type === "GROUND_IMAGE") {
      var fullUserImageUrl = roof.userImageUrl;

      var groundCornersModified = checkIfCornersChanged(
        roof.initialGroundCorners,
        roof.userImageCorners
      );
      var selectedGroundCorners = this.transformCorners(roof.userImageCorners);

      var userImageModel = {
        imageUrl: fullUserImageUrl,
        imageAngle: roof.userImageAngle,
        corners: selectedGroundCorners,
        cornersModified: groundCornersModified,
      };

      data["userImage"] = userImageModel;
    }

    data["satellite_corners"] = corners;
    data["added_space"] = ADDED_SPACE;
    data["panel_width_ratio"] = 1;
    data["panel_height_ratio"] = 1;

    if (roof.panelHeightRatio && isNumber(roof.panelHeightRatio)) {
      data["panel_height_ratio"] = roof.panelHeightRatio;
    }

    if (roof.panelWidthRatio && isNumber(roof.panelWidthRatio)) {
      data["panel_width_ratio"] = roof.panelWidthRatio;
    }

    return data;
  }

  private transformCorners(cornersArray) {
    var corners = [];
    _forEach(cornersArray, (corner) => {
      corners.push([corner.x, corner.y]);
    });
    return corners;
  }

  setImageCorners(cornerPoints: any[]) {
    if (cornerPoints.length === 4) {
      this.onGroundImageCornersChanged.next(cornerPoints);
    }
  }

  addImageCorner(roof: ProjectRoof, newPoint) {
    roof.userImageCorners.push(newPoint);
  }

  savePlacement(canvas: HTMLCanvasElement, filename): Observable<any> {
    return null;
  }

  showSpinner(shouldShowSpinner) {
    this.onSpinnerChanged.next(shouldShowSpinner);
  }

  resetWizardData(roof: ProjectRoof) {
    roof.userImageUrl = undefined;
    roof.groundGrid = undefined;
    roof.satelliteGrid = undefined;
    roof.shareableLink = undefined;
    roof.areas = [];
    roof.selectedAreas = [];
    roof.selectedAreasCorners = [];
    roof.userImageCorners = [];
    roof.sideLength = undefined;
  }
}

function getImagePaths(project: Project) {
  return [
    ...project.initialImages.map((_, idx) => `initialImages[${idx}].url`),
    ...project.roofs.map((_, idx) => `roofs[${idx}].userImageUrl`),
    ...project.roofs.map((_, idx) => `roofs[${idx}].plannedImageUrl`),
  ];
}
