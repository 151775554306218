import _get from "lodash/get";
import _set from "lodash/set";

const RESOLVABLE_HOSTNAME = "blob.core.windows.net";

export type ResolveFunction = (uris: string[]) => Promise<string[]>;

export class ImageResolver {
  resolve: ResolveFunction;

  constructor(resolve: ResolveFunction) {
    this.resolve = resolve;
  }

  async resolveImageUri(uri: string) {
    const [resolvedUri] = await this.resolve([uri]);
    return resolvedUri;
  }

  async resolveImageUris(obj: any, paths: string[]) {
    const uris = paths
      .map((path) => ({ path, uri: _get(obj, path) }))
      .filter(({ uri }) => uri && uri.includes(RESOLVABLE_HOSTNAME));

    const resolvedUris = await this.resolve(uris.map(({ uri }) => uri));

    uris.forEach(({ path, uri }, idx) => {
      _set(obj, path, resolvedUris[idx]);
    });
  }

  cleanResolvableUris(obj: any, paths: string[]) {
    const uris = paths
      .map((path) => ({ path, uri: _get(obj, path) }))
      .filter(({ uri }) => uri && uri.includes(RESOLVABLE_HOSTNAME))
      .map(({ path, uri }) => ({ path, uri: cleanUri(uri) }))
      .forEach(({ path, uri }) => _set(obj, path, uri));
  }
}

function cleanUri(uri: string) {
  const queryStart = uri.indexOf("?");
  return queryStart !== -1 ? uri.substring(0, queryStart) : uri;
}
