import { PlanningDataService } from "./../../core/services/planning-data.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  OnInit,
  Input,
  ViewChildren,
  QueryList,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { AreaCornersSelectionCanvasComponent } from "./area-corners-selection-canvas/area-corners-selection-canvas.component";
import { Project, ProjectRoof } from "../../core/models/projectModel";

@Component({
  styleUrls: ["./area-corners-selection.component.css"],
  selector: "cm-area-corners-selection",
  templateUrl: "./area-corners-selection.component.html",
})
export class AreaCornersSelectionComponent implements OnInit {
  @Input() fullComponentVersion: boolean = true;
  @ViewChildren("areaCornersCanvasComponent")
  canvases: QueryList<AreaCornersSelectionCanvasComponent>;
  orderNumber: string;
  projectId: string;
  project: Project;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planningDataService: PlanningDataService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.orderNumber = this.route.snapshot.paramMap.get("orderNumber");
      this.projectId = this.route.snapshot.paramMap.get("projectId");
      this.project = data.project;
    });
  }

  ngOnDestroy() {}

  onAddRoof() {
    const newRoof: ProjectRoof = {
      roofPitch: 40,
    } as any;
    this.project.roofs.push(newRoof);
  }

  goBack() {
    this.router.navigate([`/projects/${this.orderNumber}/${this.projectId}`], {
      queryParamsHandling: "merge",
    });
  }

  goToProjects() {
    this.router.navigate(["/projects", this.orderNumber]);
  }

  onRemoveRoofClick(roof: ProjectRoof) {
    if (this.project.roofs.length < 2) {
      alert("The last roof cannot be deleted");
    } else if (confirm("Are you sure you want to delete this roof?")) {
      const roofIndex = this.project.roofs.indexOf(roof);
      this.project.roofs.splice(roofIndex, 1);
    }
  }

  async onGoToNext(): Promise<void> {
    try {
      for (const canvas of this.canvases.toArray()) {
        if (!(await canvas.acceptChanges())) {
          return;
        }
      }
      this.planningDataService.persist(this.project);
      this.router.navigate(
        [`/projects/${this.orderNumber}/${this.projectId}/image-upload`],
        { queryParamsHandling: "merge" }
      );
    } catch (e) {
      alert("An error has occurred: " + e);
      console.error(e);
    }
  }
}
