import { Component, Input, OnChanges } from "@angular/core";

@Component({
  styleUrls: ["./icon-label.component.scss"],
  selector: "cm-icon-label",
  templateUrl: "./icon-label.component.html",
})
export class IconLabelComponent {
  @Input() label: string;
  @Input() icon: string;
}
