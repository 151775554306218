import { map } from "rxjs/operators";
import { AreaCornersSelectionComponent } from "../../area-corners-selection/area-corners-selection.component";
import { IModalContent, ModalService } from "../../../core/modal/modal.service";
import { ImageCornersSelectionComponent } from "../../image-corners-selection/image-corners-selection.component";
import { PlanningDataService } from "../../../core/services/planning-data.service";
import { Router, Params } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { DropzoneComponent } from "ngx-dropzone-wrapper";
import { environment as env } from "../../../../environments/environment";
import { ImageCropperComponent } from "../../../core/image-cropper/component/image-cropper.component";
import { ImageCroppedEvent } from "../../../core/image-cropper/interfaces";
import {
  InitialImage,
  Project,
  ProjectRoof,
} from "../../../core/models/projectModel";
import { HttpClient, HttpHeaders } from "@angular/common/http";

export interface ImageTransform {
  scale?: number;
  rotate?: number;
  flipH?: boolean;
  flipV?: boolean;
}

@Component({
  styleUrls: ["image-upload-roof.component.css"],
  selector: "cm-image-upload-roof",
  templateUrl: "./image-upload-roof.component.html",
})
export class ImageUploadRoofComponent implements OnInit {
  config: any;
  uploadedImageUrl: string;
  canSelectCorners = false;
  allowGroundGridTesting = false;
  sideLengthMeters;
  shouldShowSideLength: boolean = false;
  sideLengthShowHideLabel: string = "Show";
  cornersLoaded = false;
  IMAGE_UPLOAD_ENDPOINT_URL: string;
  uploadImageDisabled: boolean = false;
  roofIndex: number;
  imageChangedEvent: any = "";
  croppedImage: any = "";
  imageBase64: string;
  imageLoaded: boolean;
  selectedOpportunityImage: InitialImage;
  orderNumber: string;
  scale = 1;
  googleMapsImage: string;
  modalOpened: boolean = false;
  _this: any;
  project: Project;

  @Input() public roof: ProjectRoof;
  @ViewChild("imageCornersComponent")
  public imageCornersComponent: ImageCornersSelectionComponent;
  @ViewChild("satelliteCornersComponent")
  public satelliteCornersComponent: AreaCornersSelectionComponent;
  @ViewChild(DropzoneComponent)
  @ViewChild(ImageCropperComponent)
  imageCropper: ImageCropperComponent;
  componentRef?: DropzoneComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private elRef: ElementRef,
    private planningDataService: PlanningDataService,
    private ngZone: NgZone,
    private modalService: ModalService,
    private http: HttpClient,
    private changeDetector: ChangeDetectorRef
  ) {
    this.orderNumber = this.route.snapshot.paramMap.get("orderNumber");
    this.IMAGE_UPLOAD_ENDPOINT_URL = `${env.backend.api.imageUploadURL}/${this.orderNumber}?code=${env.backend.apiKey}`;
  }

  ngOnInit() {
    const that = this;
    this.route.data.subscribe((data) => {
      this.project = data.project;
      that.uploadedImageUrl = that.roof.userImageUrl;
      if (that.uploadedImageUrl) {
        that.canSelectCorners = true;
      }
      that.sideLengthMeters = that.roof.sideLength;

      that.planningDataService.onSideLengthChanged.subscribe((sideLength) => {
        that.sideLengthMeters = sideLength;
      });
      that.planningDataService.onGroundImageCornersChanged.subscribe(
        (groundCorners) => {
          that.cornersLoaded = groundCorners && groundCorners.length === 4;
        }
      );

      // Called only the first time we load the page
      const corners = that.roof.userImageCorners;
      that.cornersLoaded = corners && corners.length === 4;

      that.config = {
        url: that.IMAGE_UPLOAD_ENDPOINT_URL,
        method: "post",
        paramName: "file",
      };
      that.roofIndex = that.project.roofs.indexOf(that.roof);
    });
  }

  async onImageSelected(image: InitialImage): Promise<void> {
    if (this.imageCornersComponent) {
      this.imageCornersComponent.removeAllCorners();
      this.uploadedImageUrl = undefined;
    }

    this.canSelectCorners = false;
    this.imageLoaded = false;
    this.roof.groundGrid = undefined;
    this.roof.userImageCorners = [];
    if (this.imageCornersComponent) {
      this.imageCornersComponent.numCorners = 0;
    }

    // If the image is stored in an s3 bucket the header and cache settings are needed, otherwise fetching the image fails.
    // If the image is in another storage (blob or googleapis) then setting the header makes it fails, therefore this if.
    // Eventually images should be proxied through the api, to prevent them being publicly exposed.
    const options: any = image.url.includes("amazonaws")
      ? {
          cache: "no-store",
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }
      : {};

    const result: any = await fetch(image.url, options);

    const blob = await result.blob();
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = async () => {
      this.imageBase64 = reader.result as string;
      this.selectedOpportunityImage = image;
    };
  }

  fileChangeEvent(event: any): void {
    if (this.imageCornersComponent) {
      this.imageCornersComponent.removeAllCorners();
      this.uploadedImageUrl = undefined;
    }

    if (event.target.files.length < 1) {
      return;
    }

    this.canSelectCorners = false;
    this.imageLoaded = false;

    const roofIndex = this.project.roofs.indexOf(this.roof);
    const fileName: string = `${roofIndex}_${event.target.files[0].name}`;
    this.imageChangedEvent = event;
    this.selectedOpportunityImage = {
      id: fileName,
      name: fileName,
      category: "upload",
      url: "",
    };

    const reader = new FileReader();
    reader.readAsDataURL(event.target.files[0]);
    reader.onload = () => (this.imageBase64 = reader.result as string);
  }

  onImageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
  }

  onImageLoaded() {
    this.imageLoaded = true;
  }

  async onCropSelected(autoDetectCorners: boolean) {
    try {
      this.uploadImageDisabled = true;
      this.roof.groundGrid = undefined;
      this.roof.userImageCorners = [];
      if (this.imageCornersComponent) {
        this.imageCornersComponent.numCorners = 0;
      }

      // Convert cropper base64 output to a blob
      const b64String = this.croppedImage
        ? this.croppedImage
        : this.imageBase64;
      const res = await fetch(b64String);
      const blob = await res.blob();

      // Gets the roof index, to add it as prefix of the file name. This is necessary if the user picks the same image on multiple roofs.
      const roofIndex = this.project.roofs.indexOf(this.roof);
      const fileName = `${roofIndex}_${this.selectedOpportunityImage.name}`;

      this.uploadedImageUrl = await this.planningDataService.uploadImage(
        fileName,
        this.orderNumber,
        await blob
      );
      this.roof.userImageUrl = this.uploadedImageUrl;

      // Clears previous data
      if (this.imageCornersComponent) {
        this.imageCornersComponent.removeAllCorners();
      }

      if (autoDetectCorners) {
        // Do an API call to auto-detect the corners
        if (!(await this.detectCornersFromGroundImage())) {
          return;
        }
      }
      this.canSelectCorners = true;
    } catch (e) {
      alert(e.message);
    } finally {
      this.uploadImageDisabled = false;
    }
  }

  async acceptChanges() {
    if (this.roof.locked) {
      return;
    }

    if (this.satelliteCornersComponent) {
      const areaCorners = this.roof.selectedAreasCorners;

      if (areaCorners && areaCorners.length !== 4) {
        alert("Please mark the 4 corners on the satellite image.");
        return;
      } else {
        // Set the updated area corners
        this.roof.selectedAreasCorners = areaCorners;
      }
    }

    const imageCorners = this.imageCornersComponent.getImageCorners();

    // Check the image corners
    if (imageCorners && imageCorners.length === 4) {
      this.roof.userImageCorners = imageCorners;
      const gridData =
        await this.planningDataService.submitInfoForPlacementOnUserImage(
          this.roof
        );
      this.roof.groundGrid = gridData.grid;
      this.roof.userImageUrl = this.uploadedImageUrl;

      if (gridData.azimuth) {
        this.roof.azimuth = gridData.azimuth;
      }
    } else {
      alert("Please mark the 4 corners on the ground image roof.");
    }
  }

  async detectCornersFromGroundImage() {
    try {
      const cornersInfo =
        await this.planningDataService.detectGroundImageCorners(this.roof);
      if (cornersInfo.corners.length !== 4) {
        alert(
          'The corners on this roof could not be automatically detected. Please use the "Manual Grid" option.'
        );
        return false;
      }
      if (cornersInfo.sideLengthMeters) {
        this.roof.sideLength = cornersInfo.sideLengthMeters;
        this.roof.initialSideLength = cornersInfo.sideLengthMeters;
      }

      if (cornersInfo.groundImageAreas) {
        this.roof.groundImageAreas = cornersInfo.groundImageAreas;
      }

      var corners = [];
      _forEach(cornersInfo.corners, (cornerPoint) => {
        corners.push({ x: cornerPoint[0], y: cornerPoint[1] });
      });
      this.roof.userImageCorners = corners;
      this.roof.initialGroundCorners = corners;
      return true;
    } catch (e) {
      console.error(e);
      alert(
        `Could not detect the image corners! Please Try manually. Exception was: ${e.message}`
      );
      return false;
    }
  }

  onModifySideLength() {
    const modalContent: IModalContent = {
      header: "Modify the Side Length",
      body:
        'If you have more accurate information about the side length, you can modify it here.<form><div class="form-group medium-space"><div class="text-field-middle"><input type="text" class="form-control" id="updated-side-length_' +
        this.roofIndex +
        '" value="' +
        this.sideLengthMeters +
        '"></div></div></form>',
      cancelButtonText: "Cancel",
      OKButtonText: "OK",
    };
    this.modalService.show(modalContent).then((success) => {
      if (success) {
        const newSideLength = this.elRef.nativeElement.querySelector(
          `#updated-side-length_${this.roofIndex}`
        ).value;
        this.roof.sideLength = parseFloat(newSideLength);
      }
    });
  }

  onToggleSideLength() {
    this.shouldShowSideLength = !this.shouldShowSideLength;
    if (this.shouldShowSideLength) {
      this.sideLengthShowHideLabel = "Hide";
    } else {
      this.sideLengthShowHideLabel = "Show";
    }
    return this.shouldShowSideLength;
  }

  rotateLeft() {
    this.imageCropper.rotateLeft();
  }

  rotateRight() {
    this.imageCropper.rotateRight();
  }

  flipHorizontal() {
    this.imageCropper.flipHorizontal();
  }

  flipVertical() {
    this.imageCropper.flipVertical();
  }

  resetImage() {
    this.imageCropper.resetImage();
  }

  zoomOut() {
    this.scale -= 0.1;
    this.imageCropper.scale(this.scale);
  }

  zoomIn() {
    this.scale += 0.1;
    this.imageCropper.scale(this.scale);
  }

  displayCorners() {
    this.modalOpened = true;
  }

  modalBackgroundClick($event) {
    if ($event.target.classList.contains("img-corners__modal")) {
      this.modalOpened = false;
    }
  }
}
