export function sortClockwise(points) {
  // Array of points;

  // Find min max to get center
  // Sort from top to bottom
  points.sort((a, b) => a.y - b.y);

  // Get center y
  const cy = (points[0].y + points[points.length - 1].y) / 2;

  // Sort from right to left
  points.sort((a, b) => b.x - a.x);

  // Get center x
  const cx = (points[0].x + points[points.length - 1].x) / 2;

  // Center point
  const center = { x: cx, y: cy };

  // Pre calculate the angles as it will be slow in the sort
  // As the points are sorted from right to left the first point
  // is the rightmost

  // Starting angle used to reference other angles
  var startAng;
  points.forEach((point) => {
    var ang = Math.atan2(point.y - center.y, point.x - center.x);
    if (!startAng) {
      startAng = ang;
    } else {
      if (ang < startAng) {
        // ensure that all points are clockwise of the start point
        ang += Math.PI * 2;
      }
    }
    point.angle = ang; // add the angle to the point
  });

  // Sort clockwise;
  points.sort((a, b) => a.angle - b.angle);

  for (var i = 0; i < 2; i++) {
    points.push(points.shift());
  }

  return points;
}

export function pointInPolygon(x, y, vs): boolean {
  // ray-casting algorithm based on
  // http://www.ecse.rpi.edu/Homepages/wrf/Research/Short_Notes/pnpoly.html

  var inside = false;
  for (var i = 0, j = vs.length - 1; i < vs.length; j = i++) {
    var xi = vs[i][0],
      yi = vs[i][1];
    var xj = vs[j][0],
      yj = vs[j][1];

    var intersect =
      yi > y != yj > y && x < ((xj - xi) * (y - yi)) / (yj - yi) + xi;
    if (intersect) inside = !inside;
  }
  return inside;
}

export function base64toBlob(b64Data, contentType, sliceSize?) {
  contentType = contentType || "";
  sliceSize = sliceSize || 512;

  var byteCharacters = atob(b64Data);
  var byteArrays = [];

  for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    var slice = byteCharacters.slice(offset, offset + sliceSize);

    var byteNumbers = new Array(slice.length);
    for (var i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  var blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

// this finds 2D lines intersection
export function intersectLines(x1, y1, x2, y2, x3, y3, x4, y4) {
  let A1 = y2 - y1,
    B1 = x1 - x2,
    C1 = A1 * x1 + B1 * y1;
  let A2 = y4 - y3,
    B2 = x3 - x4,
    C2 = A2 * x3 + B2 * y3;
  let D = A1 * B2 - A2 * B1;
  if (D != 0) {
    return {
      x: (B2 * C1 - B1 * C2) / D,
      y: (A1 * C2 - A2 * C1) / D,
    };
  }
}

export function findHorizon(data, area?) {
  // find two horizon points (horizontal and vertical intersections)
  let hs = [],
    vs = [];

  for (let i = 0; i < data.length; i++) {
    let p = data[i];

    if (area !== undefined && p.area !== area) continue;

    let hp = intersectLines(
      p[0][0],
      p[0][1],
      p[1][0],
      p[1][1],
      p[2][0],
      p[2][1],
      p[3][0],
      p[3][1]
    );
    let vp = intersectLines(
      p[0][0],
      p[0][1],
      p[3][0],
      p[3][1],
      p[1][0],
      p[1][1],
      p[2][0],
      p[2][1]
    );
    if (hp && vp) {
      if (
        Math.max(
          Math.abs(hp.x),
          Math.abs(hp.y),
          Math.abs(vp.x),
          Math.abs(vp.y)
        ) > 1234567890
      ) {
        hp = null;
        vp = null;
      }

      if (hp) hs.push(hp);
      if (vp) vs.push(vp);
    }
  }

  if (hs.length == 0) hs.push({ x: 1234567890, y: data[0][0][1] });
  if (vs.length == 0) vs.push({ x: data[0][0][0], y: -123456789 });

  let ahs = { x: hs[0].x, y: hs[0].y };
  let avs = { x: vs[0].x, y: vs[0].y };

  for (let i = 1; i < hs.length; i++) {
    ahs.x += hs[i].x;
    ahs.y += hs[i].y;
  }
  ahs.x /= hs.length;
  ahs.y /= hs.length;
  for (let i = 1; i < vs.length; i++) {
    avs.x += vs[i].x;
    avs.y += vs[i].y;
  }
  avs.x /= vs.length;
  avs.y /= vs.length;

  // we have averages, TODO maybe use medians for nearly parallel lines to work better

  return { ahs: ahs, avs: avs };
}

export function checkIfCornersChanged(initial, current): boolean {
  // TODO: Compare getCorners() with initialCorners
  // If the same, then return false

  if (
    (initial && !current) ||
    (!initial && current) ||
    initial.length !== current.length
  ) {
    return true;
  }

  for (var i = 0; i < initial.length; i++) {
    var initCorner = initial[i];
    var cornerExists = false;
    for (var j = 0; j < current.length; j++) {
      var currCorner = current[j];
      if (
        initCorner["x"] == currCorner["x"] &&
        initCorner["y"] == currCorner["y"]
      ) {
        cornerExists = true;
        break;
      }
    }

    if (!cornerExists) {
      return true;
    }
  }

  return false;
}
