import { Component, Input, Output, EventEmitter } from "@angular/core";

import { Units, availableUnits } from "../units";

@Component({
  styleUrls: ["./unit-toggle-button.component.scss"],
  selector: "cm-unit-toggle-button",
  templateUrl: "./unit-toggle-button.component.html",
})
export class UnitToggleButtonComponent {
  units: any = withActiveUnit(Units.Tiles);

  @Input() activeUnit = Units.Tiles;
  @Output() activeUnitChange = new EventEmitter<Units>();

  onUseUnit(unit: Units) {
    this.units = withActiveUnit(unit);
    this.activeUnitChange.emit(unit);
  }
}

function withActiveUnit(unit: Units) {
  return availableUnits.map((u) => ({
    ...u,
    isActive: u.unit === unit,
  }));
}
