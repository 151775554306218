import { LoadConfigPage } from "./load-config-page/load-config-page";
import { ImageCornersSelectionComponent } from "./planning-data-page/image-corners-selection/image-corners-selection.component";
import { ImageUploadComponent } from "./planning-data-page/image-upload/image-upload.component";
import { AreaCornersSelectionComponent } from "./planning-data-page/area-corners-selection/area-corners-selection.component";
import { AreaCornersSelectionCanvasComponent } from "./planning-data-page/area-corners-selection/area-corners-selection-canvas/area-corners-selection-canvas.component";
import { PanelPlacingComponent } from "./planning-data-page/panel-placing-component/panel-placing.component";
import { MapSelectionComponent } from "./planning-data-page/map-selection-component/map-selection.component";
import { PlanningDataService } from "./core/services/planning-data.service";
import { ReactiveFormsModule } from "@angular/forms";
import { PlanningDataPageComponent } from "./planning-data-page/planning-data-page.component";
import { IonRangeSliderModule } from "ng2-ion-range-slider";
import { AuthGuard } from "./core/interceptors/auth-guard";
import { JwtInterceptor } from "./core/interceptors/jwt.interceptor";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { LoginModule } from "./login/login.module";
import { NgModule, APP_INITIALIZER } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { CoreModule } from "./core/core.module";
import { SharedModule } from "./shared/shared.module";
import { HttpModule } from "@angular/http";
import { DropzoneModule } from "ngx-dropzone-wrapper";
import { DROPZONE_CONFIG } from "ngx-dropzone-wrapper";
import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";
import { AgmCoreModule } from "@agm/core";
import { LoadingBarHttpModule } from "@ngx-loading-bar/http";
import { LoadingBarRouterModule } from "@ngx-loading-bar/router";
import { LoadingBarModule } from "@ngx-loading-bar/core";
import { ClipboardModule } from "ngx-clipboard";
import { ImageUploadRoofComponent } from "./planning-data-page/image-upload/image-upload-roof/image-upload-roof.component";
import { PanelPlacingCanvasComponent } from "./planning-data-page/panel-placing-component/panel-placing-canvas/panel-placing-canvas.component";
import { PanelCalculationPageComponent } from "./panel-calculation-page/panel-calculation-page.component";
import { TileCalculationTableComponent } from "./panel-calculation-page/tile-calculation-table/tile-calculation-table.component";
import { SafetyDistancesTableComponent } from "./panel-calculation-page/safety-distances-table/safety-distances-table.component";
import { UnitToggleButtonComponent } from "./panel-calculation-page/unit-toggle-button/unit-toggle-button.component";
import { IconLabelComponent } from "./panel-calculation-page/icon-label/icon-label.component";

import { ImageCropperModule } from "./core/image-cropper/image-cropper.module";
import { ProjectsPageComponent } from "./projects-page/projects-page.component";
import { ProjectResolver } from "./core/projectResolver";
import { LoadingService } from "./core/services/loading.service";
import { environment as env } from "../environments/environment";
import { MsalModule, MsalRedirectComponent } from "@azure/msal-angular";
import { InteractionType, PublicClientApplication } from "@azure/msal-browser";

const DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
  // Change this to your upload POST address:
  url: "https://httpbin.org/post",
  maxFilesize: 50,
  acceptedFiles: "image/*",
};

const app = new PublicClientApplication({
  auth: {
    clientId: env.auth.b2cAppClientId,
    authority:
      "https://enpalcraftos.b2clogin.com/enpalcraftos.onmicrosoft.com/B2C_1A_SIGNUP_SIGNIN_CRAFT",
    knownAuthorities: ["enpalcraftos.b2clogin.com"],
    redirectUri: window.location.origin,
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false,
  },
});

@NgModule({
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    AppRoutingModule, // Main routes for application
    CoreModule, // Singleton objects (services, components that are loaded only once, etc.)
    SharedModule, // Shared (multi-instance) objects
    HttpModule,
    LoginModule,
    DropzoneModule,
    IonRangeSliderModule,
    LoadingBarHttpModule,
    LoadingBarRouterModule,
    ClipboardModule,
    LoadingBarModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: "AIzaSyBKCTwTmBFpqj3AnRdPOYY-jyVPo5L9e0U",
      libraries: ["places"],
    }),
    ImageCropperModule,
    MsalModule.forRoot(
      app,
      {
        interactionType: InteractionType.Redirect,
        authRequest: {
          scopes: ["openid", env.auth.b2cAppClientId],
        },
      },
      null
    ),
  ],
  declarations: [
    AppComponent,
    PlanningDataPageComponent,
    MapSelectionComponent,
    PanelPlacingComponent,
    AreaCornersSelectionComponent,
    ImageUploadComponent,
    ImageCornersSelectionComponent,
    LoadConfigPage,
    AreaCornersSelectionCanvasComponent,
    ImageUploadRoofComponent,
    PanelPlacingCanvasComponent,
    PanelCalculationPageComponent,
    TileCalculationTableComponent,
    SafetyDistancesTableComponent,
    UnitToggleButtonComponent,
    IconLabelComponent,
    ProjectsPageComponent,
  ],
  providers: [
    AuthGuard,
    PlanningDataService,
    LoadingService,
    ProjectResolver,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: DROPZONE_CONFIG,
      useValue: DEFAULT_DROPZONE_CONFIG,
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent],
})
export class AppModule {}
