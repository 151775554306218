import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { catchError } from "rxjs/operators";
import _set from "lodash/set";
import { Project } from "./models/projectModel";
import { PlanningDataService } from "./services/planning-data.service";

@Injectable()
export class ProjectResolver implements Resolve<any> {
  private static _project: Project;

  constructor(private planningDataService: PlanningDataService) {}

  public static reset = () => {
    ProjectResolver._project = undefined;
  };

  async resolve(route: ActivatedRouteSnapshot): Promise<Project> {
    if (ProjectResolver._project) {
      return ProjectResolver._project;
    }

    const orderNumber = route.paramMap.get("orderNumber");
    const projectId = route.paramMap.get("projectId");
    try {
      const project = await this.planningDataService.getProject(
        orderNumber,
        projectId
      );
      ProjectResolver._project = project;
      return project;
    } catch (e) {
      alert(`An error has occurred: ${e.message}`);
      throw e;
    }
  }
}
