import { Subscription } from "rxjs";
import { findHorizon, intersectLines, sortClockwise } from "../../shared/utils";
import {
  CORNER_POINT_RADIUS,
  ANCHOR_POINT_RADIUS,
  ADDED_SPACE,
} from "./../../shared/constants";
import { PlanningDataService } from "./../../core/services/planning-data.service";
import { Router } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { HostListener } from "@angular/core";
import { ProjectRoof } from "../../core/models/projectModel";

declare var fabric: any;

@Component({
  styleUrls: ["./image-corners-selection.component.css"],
  selector: "cm-image-corners-selection",
  templateUrl: "./image-corners-selection.component.html",
})
export class ImageCornersSelectionComponent implements OnInit {
  shapeGroups: any = [];
  config = {};
  mapConfig: any;
  center: any;
  public staticImg: string = "";
  selectedAreas: any;
  canvas: any;
  canvasWidth;
  scaleFactor = 1;
  numCorners = 0;
  imageCorners: any[] = [];
  manualCorners = [];

  cornersSub = Subscription;
  userImgSub = Subscription;

  @Input() roof: ProjectRoof;
  @ViewChild("canvasWrapper") public canvasWrapperElementRef: ElementRef;
  @ViewChild("canvas") public canvasElementRef: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private ngZone: NgZone,
    private planningDataService: PlanningDataService
  ) {}

  ngOnInit() {
    const that = this;
    that.imageCorners = that.roof.userImageCorners;
    that.initCanvas();

    that.planningDataService.onUserImageChanged.subscribe((userImageUrl) => {
      that.staticImg = userImageUrl;
      that.setImageBg();
    });

    // that.roofService.onGroundImageCornersChanged.subscribe((groundCorners) => {
    //   that.removeAllCorners();
    //   that.imageCorners = groundCorners;
    //   that.displayCorners();
    // });
  }

  ngOnDestroy() {}

  initCanvas() {
    this.canvas = new fabric.Canvas(this.canvasElementRef.nativeElement);
    const that = this;
    fabric.CustomGrid = fabric.util.createClass(fabric.Object, {
      type: "grid",
      render: function (ctx) {
        let imageCorners = that.getImageCorners();
        if (imageCorners.length >= 4) {
          // we want these clockwise
          imageCorners = sortClockwise(imageCorners);

          let horizon = findHorizon([
            [
              [imageCorners[0].x, imageCorners[0].y],
              [imageCorners[1].x, imageCorners[1].y],
              [imageCorners[2].x, imageCorners[2].y],
              [imageCorners[3].x, imageCorners[3].y],
            ],
          ]);
          // we have horizon.ahs-avs.x-y now
          let subdivide = function (corners) {
            let center = intersectLines(
              corners[0].x,
              corners[0].y,
              corners[2].x,
              corners[2].y,
              corners[1].x,
              corners[1].y,
              corners[3].x,
              corners[3].y
            );
            let midpoints = [
              intersectLines(
                center.x,
                center.y,
                horizon.avs.x,
                horizon.avs.y,
                corners[0].x,
                corners[0].y,
                corners[1].x,
                corners[1].y
              ),
              intersectLines(
                center.x,
                center.y,
                horizon.ahs.x,
                horizon.ahs.y,
                corners[1].x,
                corners[1].y,
                corners[2].x,
                corners[2].y
              ),
              intersectLines(
                center.x,
                center.y,
                horizon.avs.x,
                horizon.avs.y,
                corners[2].x,
                corners[2].y,
                corners[3].x,
                corners[3].y
              ),
              intersectLines(
                center.x,
                center.y,
                horizon.ahs.x,
                horizon.ahs.y,
                corners[3].x,
                corners[3].y,
                corners[0].x,
                corners[0].y
              ),
            ];
            return [
              [corners[0], midpoints[0], center, midpoints[3]],
              [midpoints[0], corners[1], midpoints[1], center],
              [center, midpoints[1], corners[2], midpoints[2]],
              [midpoints[3], center, midpoints[2], corners[3]],
            ];
          };

          ctx.strokeStyle = "rgb(255, 255, 0)";
          ctx.lineWidth = 0.7;
          ctx.beginPath();
          // subdivide original image corners twice
          subdivide(imageCorners).forEach(function (corners) {
            subdivide(corners).forEach(function (corners2) {
              subdivide(corners2).forEach(function (corners3) {
                subdivide(corners3).forEach(function (corners4) {
                  corners4.forEach(function (corner, index) {
                    ctx[index ? "lineTo" : "moveTo"](
                      corner.x * that.scaleFactor,
                      corner.y * that.scaleFactor
                    );
                  });
                  ctx.lineTo(
                    corners4[0].x * that.scaleFactor,
                    corners4[0].y * that.scaleFactor
                  );
                });
              });
            });
          });
          ctx.stroke();
        }
      },
    });
    this.canvas.add(new fabric.CustomGrid());

    // Add the mouse listener for the corners
    fabric.util.addListener(
      this.canvas.upperCanvasEl,
      "dblclick",
      function (event) {
        const currPoint = that.canvas.getPointer(event);
        that.addCircle.bind(that)(currPoint);
      }
    );

    fabric.util.addListener(
      this.canvas.upperCanvasEl,
      "mouseup",
      function (event) {
        const activeObj = that.canvas.getActiveObject();
        if (activeObj && activeObj.type && activeObj.type === "corner") {
        }
      }
    );

    let updateMouse = (event) => {
      this.mouse = this.getMousePosition(event);
    };

    fabric.util.addListener(this.canvas.upperCanvasEl, "mousedown", (event) => {
      updateMouse(event);
      this.mouseDown = true;
    });

    fabric.util.addListener(this.canvas.upperCanvasEl, "mousemove", (event) => {
      updateMouse(event);
    });

    let releaseMouse = () => {
      this.mouseDown = false;
    };
    fabric.util.addListener(this.canvas.upperCanvasEl, "mouseup", releaseMouse);
    fabric.util.addListener(
      this.canvas.upperCanvasEl,
      "mouseout",
      releaseMouse
    );

    fabric.util.addListener(this.canvas.upperCanvasEl, "wheel", (event) => {
      updateMouse(event);
      event.preventDefault();
      if (event.deltaY < 0) this.zoomIn();
      if (event.deltaY > 0) this.zoomOut();
    });
  }

  addCircle(currPoint: { x: number; y: number }) {
    if (this.numCorners > 3) {
      return;
    }

    this.numCorners++;

    let fill = "red";
    if (this.numCorners === 1) {
      fill = "yellow";
    } else if (this.numCorners === 2) {
      fill = "#0eff42";
    } else if (this.numCorners === 3) {
      fill = "magenta";
    }

    const circle = this.createCornerCircle(
      {
        x: currPoint.x,
        y: currPoint.y,
      },
      { name: this.numCorners, fill: fill }
    );
    this.canvas.add(circle);

    this.manualCorners.push([currPoint.x, currPoint.y]);
    this.roof.userImageCorners = this.getImageCorners();

    this.roof.initialGroundCorners = this.manualCorners.map((point, index) => ({
      x: point[0],
      y: point[1],
      name: index,
    }));
  }

  setImageBg() {
    const that = this;
    this.staticImg = this.roof.userImageUrl;
    const bgImgUrl = this.staticImg;
    fabric.Image.fromURL(bgImgUrl, (bgImg) => {
      const maxHeight = 480;
      let imgMargin = bgImg.width * ADDED_SPACE;
      imgMargin = (bgImg.width * 20) / 100;
      let canvasWidth = bgImg.width + imgMargin * 2;
      let canvasHeight = bgImg.height;
      this.roof.imageSize = {
        heigth: bgImg.height,
        width: bgImg.width,
      };

      if (canvasHeight > maxHeight) {
        // We should scale the image and the canvas size
        var heightRatio = canvasHeight / canvasWidth;
        this.scaleFactor = maxHeight / canvasHeight;
        canvasWidth = (canvasWidth * maxHeight) / canvasHeight;
        canvasHeight = maxHeight;
      }

      that.canvas.setHeight(canvasHeight);
      that.canvas.setWidth(canvasWidth);
      that.canvasWidth = canvasWidth;
      that.canvas.setBackgroundImage(
        bgImg,
        that.canvas.renderAll.bind(that.canvas),
        {
          left: imgMargin * this.scaleFactor,
          scaleX: this.scaleFactor,
          scaleY: this.scaleFactor,
        }
      );

      that.rotateImage(that.roof.userImageAngle);
      that.displayCorners();
    });
  }

  rotateImage(degrees) {
    const currentAngle = this.canvas.backgroundImage.angle;
    const newAngle = (currentAngle + degrees) % 360;
    this.canvas.backgroundImage.rotate(newAngle);
    this.canvas.renderAll();
    this.roof.userImageAngle = newAngle;
  }

  removeAllCorners() {
    var that = this;
    var allCornerObjects = _filter(this.canvas.getObjects(), (obj) => {
      return obj.type === "corner";
    });

    _forEach(allCornerObjects, (cornerCircle) => {
      that.canvas.remove(cornerCircle);
    });
  }

  displayCorners() {
    const that = this;
    that.numCorners = 0;
    that.manualCorners = [];

    _forEach(this.imageCorners, (point, index) => {
      that.numCorners++;

      let fill = "red";
      if (index === 0) {
        fill = "yellow";
      } else if (index === 1) {
        fill = "#0eff42";
      } else if (index === 2) {
        fill = "magenta";
      }

      const circle = that.createCornerCircle(
        {
          x: point.x * that.scaleFactor,
          y: point.y * that.scaleFactor,
        },
        { name: that.numCorners, fill: fill }
      );
      that.canvas.add(circle);
    });
  }

  private mouse = { x: 0, y: 0 };
  private mouseDown = false;

  getMousePosition(event: MouseEvent) {
    var rect = this.canvasElementRef.nativeElement.getBoundingClientRect();
    return {
      x: (event.clientX - rect.left) / this.canvas.getZoom(),
      y: (event.clientY - rect.top) / this.canvas.getZoom(),
    };
  }

  createCornerCircle(position: { x: number; y: number }, options?) {
    let fill = "red";
    if (options.fill) {
      fill = options.fill;
    }

    const config = {
      type: "corner",
      left: position.x,
      top: position.y,
      radius: CORNER_POINT_RADIUS,
      stroke: fill,
      strokeWidth: 2,
      fill: "rgba(0,0,0,0)",
      originX: "center",
      originY: "center",
      centeredRotation: true,
      cornerStyle: "circle",
      cornerSize: 5,
      cornerColor: "green",
      padding: 3,
      hasControls: false,
      hasBorders: true,
    };

    if (options) {
      if (options.name) {
        config["name"] = options.name;
      }

      if (options.shapeGroup) {
        config["shapeGroup"] = options.shapeGroup;
      }

      if (options.index) {
        config["index"] = options.index;
      }
    }

    const circle = new fabric.Circle(config);
    return circle;
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.handleKeypress(event);
  }

  handleKeypress($event) {
    var keyPressed = $event.key;
    if (keyPressed === "Delete") {
      this.handleCanvasDelete();
    }
  }

  handleCanvasDelete() {
    // Will delete the active Object
    var activeObj = this.canvas.getActiveObject();

    if (activeObj.type && activeObj.type === "corner") {
      this.numCorners--;
    }
    this.canvas.remove(activeObj);
  }

  // TODO: Can be factored out into utilities
  displayAreas(areas, canvas, options?) {
    // TODO: Display the areas on the canvas
  }

  zoomInOut(out) {
    const ivpt = fabric.util.invertTransform(this.canvas.viewportTransform);
    const zoom = ivpt[0];
    const offsetX = ivpt[4];
    const offsetY = ivpt[5];
    const localX =
      this.mouse.x * this.canvas.viewportTransform[0] * zoom + offsetX;
    const localY =
      this.mouse.y * this.canvas.viewportTransform[0] * zoom + offsetY;
    const newZoom = out ? Math.min(1, zoom * 1.1) : Math.max(1e-2, zoom / 1.1);

    const newWidth = newZoom * this.canvas.getWidth();
    const newHeight = newZoom * this.canvas.getHeight();
    const newOffsetX = localX - ((localX - offsetX) * newZoom) / zoom;
    const newOffsetY = localY - ((localY - offsetY) * newZoom) / zoom;

    ivpt[4] = Math.max(
      0,
      Math.min(this.canvas.getWidth() - newWidth, newOffsetX)
    );
    ivpt[5] = Math.max(
      0,
      Math.min(this.canvas.getHeight() - newHeight, newOffsetY)
    );
    ivpt[0] = ivpt[3] = newZoom;

    this.canvas.setViewportTransform(fabric.util.invertTransform(ivpt));
  }

  zoomIn() {
    this.zoomInOut(false);
  }

  zoomOut() {
    this.zoomInOut(true);
  }

  getImageCorners() {
    const allCornerObjects = _filter(this.canvas.getObjects(), (obj) => {
      return obj.type === "corner";
    });

    const cornersArray = [];
    _forEach(allCornerObjects, (cornerCircle) => {
      cornersArray.push({
        x: cornerCircle.left / this.scaleFactor,
        y: cornerCircle.top / this.scaleFactor,
        name: cornerCircle.name,
      });
    });

    return cornersArray;
  }
}
