import { PlanningDataPageComponent } from "./planning-data-page/planning-data-page.component";
import { PanelCalculationPageComponent } from "./panel-calculation-page/panel-calculation-page.component";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, PreloadAllModules } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { ProjectsPageComponent } from "./projects-page/projects-page.component";
import { ProjectResolver } from "./core/projectResolver";
import { BrowserUtils } from "@azure/msal-browser";

const app_routes: Routes = [
  {
    path: "projects/:orderNumber",
    component: ProjectsPageComponent,
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId",
    component: PlanningDataPageComponent,
    data: { visible: "MAP_SELECTION" },
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId/areas",
    component: PlanningDataPageComponent,
    data: { visible: "DISPLAY_AREA" },
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId/area-corners",
    component: PlanningDataPageComponent,
    data: { visible: "AREA_CORNERS_SELECTION" },
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId/image-upload",
    component: PlanningDataPageComponent,
    data: { visible: "IMAGE_UPLOAD" },
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId/image-corners",
    component: PlanningDataPageComponent,
    data: { visible: "USER_IMAGE_CORNERS_SELECTION" },
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId/panel-placing",
    component: PlanningDataPageComponent,
    data: { visible: "PANEL_PLACING" },
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
  {
    path: "projects/:orderNumber/:projectId/panel-calculation",
    component: PanelCalculationPageComponent,
    resolve: { project: ProjectResolver },
    canActivate: [MsalGuard],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(app_routes, {
      preloadingStrategy: PreloadAllModules,
      paramsInheritanceStrategy: "always",
      initialNavigation:
        !BrowserUtils.isInIframe() && !BrowserUtils.isInPopup()
          ? "enabled"
          : "disabled", // Set to enabledBlocking to use Angular Universal
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
  isIframe = window !== window.parent && !window.opener;
}
