import { findHorizon } from "../../../shared/utils";
import { ModalService, IModalContent } from "../../../core/modal/modal.service";
import { PANEL_TYPES, ADDED_SPACE } from "../../../shared/constants";
import { PlanningDataService } from "../../../core/services/planning-data.service";
import { Router, Params } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  ElementRef,
  Input,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { HostListener } from "@angular/core";
import {
  GrowlerService,
  GrowlerMessageType,
} from "../../../core/growler/growler.service";
import * as THREE from "three";
import { Project, ProjectRoof } from "../../../core/models/projectModel";

(window as any).THREE = THREE;
declare var require;
require("three/examples/js/controls/TransformControls");

var maskingTexture = (function () {
  let image = new Image();
  let texture = new THREE.Texture(image);
  image.onload = function () {
    texture.needsUpdate = true;
  };
  // transparent pixel placeholder
  image.src =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";
  texture.size = new THREE.Vector2(1, 1);
  texture.wrapS = texture.wrapT = THREE.ClampToEdgeWrapping;
  texture.minFilter = THREE.LinearFilter;
  return texture;
})();

function makeShaderMaterial(params) {
  let vs =
    "varying vec2 vUv; void main() { vUv = uv; gl_Position = projectionMatrix * (modelViewMatrix * vec4( position, 1.0 ));\
    gl_Position.z -= " +
    (params.depthFix || 0).toPrecision(3) +
    ";\
  }";
  let fs =
    "varying vec2 vUv; uniform float opacity; uniform vec3 fill; uniform sampler2D mask; uniform vec2 size; void main () {\
    if ( texture2D (mask, gl_FragCoord.xy / size).a > 0.1 ) discard;\
    gl_FragColor = vec4(fill, opacity);\
  }";
  let us: any = {
    opacity: { value: 1 },
    fill: { value: params.color },
    mask: { value: maskingTexture },
    size: { value: maskingTexture.size },
  };

  if (params.map) {
    fs =
      "varying vec2 vUv; uniform float opacity; uniform sampler2D map; uniform sampler2D mask; uniform vec2 size; void main () {\
      if ( texture2D (mask, gl_FragCoord.xy / size).a > 0.1 ) discard;\
      gl_FragColor = texture2D (map, vUv);\
      gl_FragColor.a *= opacity;\
    }";
    us = {
      opacity: { value: 1 },
      map: { value: params.map },
      mask: { value: maskingTexture },
      size: { value: maskingTexture.size },
    };
  }

  return new THREE.ShaderMaterial({
    vertexShader: vs,
    fragmentShader: fs,
    uniforms: us,
    transparent: !!params.transparent,
  });
}

@Component({
  styleUrls: ["./panel-placing-canvas.component.css"],
  selector: "cm-panel-placing-canvas",
  templateUrl: "./panel-placing-canvas.component.html",
})
export class PanelPlacingCanvasComponent implements OnInit {
  private data: any[];
  private dataOffsetX: number;

  PANEL_IMAGE = "../../../../assets/img/pv-panel-blue.png";
  PANEL_HEIGHT = 5;
  FRAME_COLOR = "black"; // CSS-like color declaration
  FRAME_WIDTH = 3; // in %
  CAMERA_FOV = 75;
  PADDING = ADDED_SPACE * 100; // in %
  numPlacedPanels = 0;
  infoMessage: string;
  panelPlacementButtonText: string;
  roofIndex: number;
  locked: boolean;

  @ViewChild("canvas") public canvasElementRef: ElementRef;
  @Input() public roof: ProjectRoof;

  private maskingCanvasElementRef: ElementRef;
  @ViewChild("masking") set content(ref: ElementRef) {
    if (ref) {
      this.maskingCanvasElementRef = ref;

      // make sure placing is right
      ref.nativeElement.style.left = this.canvas.offsetLeft + "px";

      // click handlers to add or remove vertices
      ref.nativeElement.removeEventListener(
        "mousedown",
        this._addRemoveMaskVertex,
        true
      );
      this._addRemoveMaskVertex = (event) => {
        let view = this.camera.view;
        let x = (event.offsetX * view.width) / view.fullWidth + view.offsetX;
        let y = (event.offsetY * view.height) / view.fullHeight + view.offsetY;

        let closest = this.findClosestMaskVertex(x, y);
        if (closest.distance < (10 * view.width) / view.fullWidth) {
          if (event.ctrlKey) {
            // remove closest vertex
            this.maskingPolygonsCopy[closest.polygon].splice(closest.vertex, 2);
            if (this.maskingPolygonsCopy[closest.polygon].length == 0) {
              this.maskingPolygonsCopy.splice(closest.polygon, 1);
            }
          } else {
            // move the vertex
            this.maskingPolygonsCopy[closest.polygon][closest.vertex] = x;
            this.maskingPolygonsCopy[closest.polygon][closest.vertex + 1] = y;

            let moveHandler = (event) => {
              let x =
                (event.offsetX * view.width) / view.fullWidth + view.offsetX;
              let y =
                (event.offsetY * view.height) / view.fullHeight + view.offsetY;

              this.maskingPolygonsCopy[closest.polygon][closest.vertex] = x;
              this.maskingPolygonsCopy[closest.polygon][closest.vertex + 1] = y;

              this.drawMaskingPolygons(true);
            };

            let clearMoveHandler = function () {
              ref.nativeElement.removeEventListener("mousemove", moveHandler);
              window.removeEventListener("mouseup", clearMoveHandler);
            };

            ref.nativeElement.addEventListener("mousemove", moveHandler);
            window.addEventListener("mouseup", clearMoveHandler);
          }
        } else if (!event.ctrlKey) {
          // add new vertex to the current polygon
          if (this.maskingPolygonsCopy.length == 0) {
            this.maskingPolygonsCopy.push([]);
          }
          this.maskingPolygonsCopy[this.maskingPolygonsCopy.length - 1].push(
            x,
            y
          );
        }

        this.drawMaskingPolygons(true);
      };
      ref.nativeElement.addEventListener(
        "mousedown",
        this._addRemoveMaskVertex,
        true
      );

      // make sure zooming works
      ref.nativeElement.removeEventListener("wheel", this._handleWheel);
      ref.nativeElement.addEventListener("wheel", this._handleWheel);

      this.maskingPolygonsCopy.length = this.maskingPolygons.length;
      for (let i = 0; i < this.maskingPolygons.length; i++) {
        this.maskingPolygonsCopy[i] = this.maskingPolygons[i].slice();
      }

      // start new polygon
      this.maskingPolygonsCopy.push([]);

      this.drawMaskingPolygons(true);
    }
  }

  private maskingPolygons: number[][] = [];
  private maskingPolygonsCopy: number[][] = [];
  private findClosestMaskVertex(x, y) {
    let p_best = -1,
      v_best = -1,
      d_best = 1e9;
    for (let p = 0; p < this.maskingPolygonsCopy.length; p++) {
      for (let v = 0; v < this.maskingPolygonsCopy[p].length; v += 2) {
        let dx = x - this.maskingPolygonsCopy[p][v];
        let dy = y - this.maskingPolygonsCopy[p][v + 1];
        let d = Math.sqrt(dx * dx + dy * dy);
        if (d < d_best) {
          p_best = p;
          v_best = v;
          d_best = d;
        }
      }
    }
    return { polygon: p_best, vertex: v_best, distance: d_best };
  }

  private drawMaskingPolygons(showVertices: boolean) {
    // angular creates one canvas for this.maskingCanvasElementRef - we use it for both
    // masking mode UI and maskingTexture.image; however, if this is called before user
    // enters masking mode we need to create the canvas ourselves (but still only once)
    let can = this.maskingCanvasElementRef
      ? this.maskingCanvasElementRef.nativeElement
      : maskingTexture.image.nodeName == "CANVAS"
      ? maskingTexture.image
      : document.createElement("canvas");

    let ctx = can.getContext("2d");
    let view = this.camera.view;

    can.width = view.fullWidth;
    can.height = view.fullHeight;

    let scale = view.fullWidth / view.width;

    ctx.setTransform(
      scale,
      0,
      0,
      scale,
      -view.offsetX * scale,
      -view.offsetY * scale
    );

    let polys = showVertices ? this.maskingPolygonsCopy : this.maskingPolygons;

    ctx.fillStyle = "rgba(255,0,0,0.4)";
    for (let p = 0; p < polys.length; p++) {
      ctx.beginPath();
      for (let v = 0; v < polys[p].length; v += 2) {
        ctx[v ? "lineTo" : "moveTo"](polys[p][v], polys[p][v + 1]);
      }
      ctx.closePath();
      ctx.fill();
    }

    if (showVertices) {
      ctx.fillStyle = "black";
      for (let p = 0; p < this.maskingPolygonsCopy.length; p++) {
        for (let v = 0; v < this.maskingPolygonsCopy[p].length; v += 2) {
          ctx.beginPath();
          ctx.arc(
            this.maskingPolygonsCopy[p][v],
            this.maskingPolygonsCopy[p][v + 1],
            5 / scale,
            0,
            2 * Math.PI
          );
          ctx.closePath();
          ctx.fill();
        }
      }
    }

    return can;
  }

  bgImage;
  shouldShowSideLength: boolean = false;
  sideLengthShowHideLabel: string = "Show";
  azimuth: number;

  private camera: THREE.PerspectiveCamera;
  private scene: THREE.Scene;
  private renderer: THREE.WebGLRenderer;
  private transformControls: any[];
  private geometry: THREE.PlaneBufferGeometry;
  private geometryBox: THREE.BoxBufferGeometry;
  private materialBox: THREE.Material;
  materialPlaced: THREE.Material;
  materialNotPlaced: THREE.Material;
  materialSelectedToSplit: THREE.Material;
  transparencyOn = false;
  transformControlsMode = "translate";
  panelsOrientationMode = "vertical";
  mainButtonsMode = true;
  areasSplittingMode = false;
  maskingMode = false;
  sideLengthMeters;
  panelTypes: any;
  selectedPanelType: any;
  intervalId: any;
  sceneType: string;

  private _togglePanel: any;
  private _selectPanel: any;
  private _handleWheel: any;
  private _addRemoveMaskVertex: any;

  // Sometimes the canvas.addEventListener('click', this._selectPanel, true) is being called twice, resulting in a bug where the panel to split is "clicked twice", deselecting it.
  // This variable is used to control that the handler is added just once.
  private clickHandlerAdded: boolean = false;
  project: Project;
  canvas: any;
  @Input() panelWidthRatio: string = "1";
  @Input() panelHeightRatio: string = "1";

  @Input() horizontalTileCount: string = "";
  @Input() verticalTileCount: string = "";

  areas: any = {};
  getNextArea(): number {
    let i = 0;
    while (this.areas[i]) i++;
    this.areas[i] = true;
    return i;
  }

  constructor(
    protected route: ActivatedRoute,
    protected router: Router,
    protected planningDataService: PlanningDataService,
    protected ngZone: NgZone,
    private growler: GrowlerService,
    private modalService: ModalService,
    private elRef: ElementRef
  ) {
    this.panelTypes = PANEL_TYPES;
    this.selectedPanelType = this.panelTypes[0];
  }

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.project = data.project;
      this.roofIndex = this.project.roofs.indexOf(this.roof);
      this.panelsOrientationMode = this.roof.panelsOrientation;
      this.panelHeightRatio = this.toNumberString(
        this.roof.panelHeightRatio,
        "1"
      );
      this.panelWidthRatio = this.toNumberString(
        this.roof.panelWidthRatio,
        "1"
      );
      this.horizontalTileCount = this.toNumberString(
        this.roof.horizontalTileCount,
        ""
      );
      this.verticalTileCount = this.toNumberString(
        this.roof.verticalTileCount,
        ""
      );

      if (
        !this.panelsOrientationMode ||
        this.panelsOrientationMode === "vertical"
      ) {
        this.panelPlacementButtonText = "Place Horizontally";
      } else if (this.panelsOrientationMode === "horizontal") {
        this.panelPlacementButtonText = "Place Vertically";
      }

      if (this.roof.panelType) {
        this.selectedPanelType = this.panelTypes.find(
          (p) => p.id === this.roof.panelType
        );
        if (!this.selectedPanelType) {
          this.selectedPanelType = this.panelTypes[0];
        }
      }

      this.azimuth = this.roof.azimuth;

      var userImage = this.roof.userImageUrl;
      // if (!userImage) {
      //   // Navigating to the image upload step
      //   this.planningDataService.setComponentVisibility('IMAGE_UPLOAD', true);
      //   this.router.navigate(['image-upload'], { queryParamsHandling: "merge" });
      // } else {
      var gridData = this.roof.groundGrid;
      var shareableLink = this.roof.shareableLink;
      if (shareableLink && gridData) {
        var urlPrefix = window.location.protocol + "//" + window.location.host;
        var fullUrl;

        if (shareableLink.startsWith("http")) {
          fullUrl = shareableLink;
        } else if (shareableLink.startsWith("/load")) {
          fullUrl = urlPrefix + shareableLink;
        } else {
          // In this case the shareableLink is just the configId
          fullUrl = urlPrefix + "/load?configId=" + shareableLink;
        }
        this.infoMessage = `<div>You can access or share this example using this id:</div><div><a target="_blank" href="${fullUrl}">${fullUrl}</a></div>`;
      }

      this.bgImage = userImage;

      //this.numPlacedPanels = this.calculatePlacedPanels(groundImageSceneData);
      this.numPlacedPanels = this.calculatePlacedPanels(gridData);
      this.roof.numPlacedPanels = this.numPlacedPanels;
      this.sceneType = "GROUND_IMAGE";

      this.sideLengthMeters = this.roof.sideLength;
      this.planningDataService.onSideLengthChanged.subscribe((sideLength) => {
        this.sideLengthMeters = sideLength;
      });

      //this.initCanvas(groundImageSceneData, this.sceneType);
      this.initCanvas(gridData, this.sceneType);
      // }
    });
  }

  compareFn(c1: any, c2: any): boolean {
    return c1 && c2 ? c1.id === c2.id : c1 === c2;
  }

  ngOnDestroy() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  calculatePlacedPanels(data) {
    var numPanels = 0;
    _forEach(data.placed, () => {
      numPanels++;
    });

    return numPanels;
  }

  private resize(w, h) {
    this.camera.aspect = w / h;

    // adjust the zoom, just reset for now
    this.camera.setViewOffset(w, h, 0, 0, w, h);

    this.renderer.setSize(w, h);

    if (this.maskingCanvasElementRef) {
      this.maskingCanvasElementRef.nativeElement.style.left =
        this.canvas.offsetLeft + "px";
    }
  }

  private render() {
    this.renderer.render(this.scene, this.camera);
  }

  protected initCanvas(data, type) {
    const frameColorStr =
      "rgb(" + this.selectedPanelType.frame_rgb.join(",") + ")";
    const canvas = this.canvasElementRef.nativeElement;
    const canvasSize = this.roof.imageSize;

    this.changePanelsLook(this.selectedPanelType.panel, frameColorStr, 3);
    this.canvas = canvas;
    canvas.width = canvasSize.width;
    canvas.height = canvasSize.heigth;

    this.clearCanvasEventListeners(canvas);

    this.renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      antialias: true,
    });
    this.renderer.setPixelRatio(1);
    this.renderer.setSize(canvasSize.width, canvasSize.heigth);

    this.scene = new THREE.Scene();

    let loader = new THREE.TextureLoader();
    loader.load(this.PANEL_IMAGE, (texture: any) => {
      this.geometry = this.geometry || new THREE.PlaneBufferGeometry(1, 1);
      this.geometryBox =
        this.geometryBox || new THREE.BoxBufferGeometry(1, 1, 1);
      this.materialBox =
        this.materialBox ||
        makeShaderMaterial({
          color: new THREE.Color(this.FRAME_COLOR),
          transparent: true,
        });
      this.materialPlaced =
        this.materialPlaced ||
        makeShaderMaterial({ map: texture, depthFix: 0.01, transparent: true });
      this.materialNotPlaced =
        this.materialNotPlaced ||
        makeShaderMaterial({
          map: this.makeBorderTexture(0),
          transparent: true,
        });
      this.materialSelectedToSplit =
        this.materialSelectedToSplit ||
        makeShaderMaterial({
          map: this.makeBorderTexture(2),
          transparent: true,
        });

      loader.load(this.bgImage, (backgroundTexture: any) => {
        let a = this.roof.userImageAngle ? this.roof.userImageAngle : 0;
        let w = backgroundTexture.image.width;
        let h = backgroundTexture.image.height;

        if (a == 90 || a == 270) {
          w = backgroundTexture.image.height;
          h = backgroundTexture.image.width;
        }

        this.dataOffsetX = 0.01 * this.PADDING * w;

        this.processData(data, w, h);

        // find the areas
        let areas = this.data
          .map(function (e) {
            return e.area;
          })
          .filter(function (value, index, self) {
            return self.indexOf(value) === index;
          });
        if (areas.length == 0) {
          throw new Error("no data");
        }

        this.transformControls = [];

        for (let areaIndex = 0; areaIndex < areas.length; areaIndex++) {
          let area = areas[areaIndex];
          let roof = this.fitPanels(
            w,
            h,
            canvas.clientHeight,
            canvas.clientWidth / canvas.clientHeight,
            this.CAMERA_FOV,
            area
          );

          let tc = new (window as any).THREE.TransformControls(
            this.camera,
            canvas
          );
          tc.setSpace("local");
          tc.attach(roof.children[0]);
          tc.addEventListener("change", () => {
            this.render();
          });
          this.scene.add(tc);

          this.transformControls.push(tc);
        }

        let z = this.camera.far * 0.999;
        let H = z * Math.tan(0.5 * ((this.camera.fov * Math.PI) / 180)) * 2;

        let background = this.camera.children[0];
        if (background) {
          background.material.map.dispose();
        } else {
          let backgroundGeometry = new THREE.PlaneBufferGeometry(1, 1);
          backgroundGeometry.rotateZ((-Math.PI * a) / 180);
          backgroundGeometry.scale((w * H) / h, H, 1);

          background = new THREE.Mesh(
            backgroundGeometry,
            new THREE.MeshBasicMaterial({ map: backgroundTexture })
          );
          this.camera.add(background);
        }
        background.material.map = backgroundTexture;
        background.position.z = -z;

        this.resize(
          Math.floor((canvas.clientHeight * w) / h),
          canvas.clientHeight
        );

        // Load the previous zoom level
        if (!data.zoom) {
          data.zoom = Object.assign({}, this.camera.view);
        }

        // ...only if the canvas size matches
        if (
          this.camera.view.fullWidth == data.zoom.fullWidth &&
          this.camera.view.fullHeight == data.zoom.fullHeight
        ) {
          this.camera.setViewOffset(
            data.zoom.fullWidth,
            data.zoom.fullHeight,
            data.zoom.offsetX,
            data.zoom.offsetY,
            data.zoom.width,
            data.zoom.height
          );
        }

        // load the previous mask
        if (data.mask) {
          this.maskingPolygons = data.mask.map(function (array) {
            return array.slice();
          });
          this.updateMaskingTexture();
        }

        this.render();

        // We register the handler here so that it can be removed, (to not be fired twice)
        this._togglePanel = (event) => {
          if (!this.mainButtonsMode) return;

          let canvasRect = canvas.getBoundingClientRect();
          let raycaster = new THREE.Raycaster();

          raycaster.setFromCamera(
            {
              x: ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1,
              y: ((canvasRect.top - event.clientY) / canvasRect.height) * 2 + 1,
            },
            this.camera
          );

          var intersects = raycaster.intersectObjects(
            this.scene.children.filter((object) => {
              return object.name == "roof";
            }),
            true
          );
          if (intersects[0]) {
            this.data[intersects[0].object.name].placed =
              intersects[0].object.children[0].visible =
                !intersects[0].object.children[0].visible;

            if (intersects[0].object.children[0].visible) {
              this.updateNumPlacedPanels(1);
            } else {
              this.updateNumPlacedPanels(-1);
            }
            this.saveSceneSnapshot(this.sceneType);
            this.render();
          }
        };
        canvas.addEventListener("dblclick", this._togglePanel, true);

        this._selectPanel = (event) => {
          if (!this.areasSplittingMode) return;

          let canvasRect = canvas.getBoundingClientRect();
          let raycaster = new THREE.Raycaster();

          raycaster.setFromCamera(
            {
              x: ((event.clientX - canvasRect.left) / canvasRect.width) * 2 - 1,
              y: ((canvasRect.top - event.clientY) / canvasRect.height) * 2 + 1,
            },
            this.camera
          );

          var intersects = raycaster.intersectObjects(
            this.scene.children.filter((object) => {
              return object.name == "roof";
            }),
            true
          );
          if (intersects[0]) {
            if (intersects[0].object.material == this.materialNotPlaced) {
              intersects[0].object.material = this.materialSelectedToSplit;
            } else {
              intersects[0].object.material = this.materialNotPlaced;
            }
            this.render();
          }
        };

        if (!this.clickHandlerAdded) {
          canvas.addEventListener("click", this._selectPanel, true);
          this.clickHandlerAdded = true;
        }

        this._handleWheel = (event) => {
          event.preventDefault();

          let canvasRect = canvas.getBoundingClientRect();
          let mouse = {
            x: event.clientX - canvasRect.left,
            y: event.clientY - canvasRect.top,
          };

          if (event.deltaY < 0) this.zoomIn(mouse);
          if (event.deltaY > 0) this.zoomOut(mouse);

          if (this.maskingPolygons.length) {
            this.updateMaskingTexture();
          }

          if (this.maskingMode) {
            this.render();
            this.drawMaskingPolygons(true);
          }
        };
        canvas.addEventListener("wheel", this._handleWheel);

        // Save the latest scene to local storage
        this.saveSceneSnapshot(type);
      });
    });

    /*

    // TODO: Capture click event on grid rectangle
    // TODO: Fill grid rectangle with background image
    // TODO: When clicking on a grid rectangle, toggle its panel on & off

    // TODO: (LATER) Check if grid panel on includes points of an obstacle and colour BLACK.
    // Update its status when it is moved!

    */
  }

  clearCanvasEventListeners(canvas) {
    canvas.removeEventListener("dblclick", this._togglePanel, true);
    canvas.removeEventListener("wheel", this._handleWheel);
  }

  saveSceneSnapshot(type) {
    const exportedScene = this.exportData();
    this.roof.groundGrid = exportedScene;
  }

  updateNumPlacedPanels(diff) {
    this.numPlacedPanels = this.numPlacedPanels + diff;
    this.roof.numPlacedPanels = this.numPlacedPanels;
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {}

  zoomInOut(mouse, out) {
    let view = this.camera.view;
    let zoom = view.width / view.fullWidth;

    let localX = mouse.x * zoom + view.offsetX;
    let localY = mouse.y * zoom + view.offsetY;

    let newZoom = out ? Math.min(1, zoom * 1.1) : Math.max(1e-2, zoom / 1.1);

    let newWidth = newZoom * view.fullWidth;
    let newHeight = newZoom * view.fullHeight;

    let newOffsetX = localX - ((localX - view.offsetX) * newZoom) / zoom;
    let newOffsetY = localY - ((localY - view.offsetY) * newZoom) / zoom;

    this.camera.setViewOffset(
      view.fullWidth,
      view.fullHeight,
      Math.max(0, Math.min(view.fullWidth - newWidth, newOffsetX)),
      Math.max(0, Math.min(view.fullHeight - newHeight, newOffsetY)),
      newWidth,
      newHeight
    );

    this.transformControls.forEach((tc) => tc.update());
    this.render();
  }

  zoomIn(mouse) {
    this.zoomInOut(mouse, false);
  }

  zoomOut(mouse) {
    this.zoomInOut(mouse, true);
  }

  toggleTransformControlsMode() {
    // this.transformControlsMode = this.transformControlsMode == 'rotate' ? 'translate' : 'scale';
    this.transformControls.forEach((tc) => tc.setSize(2));
    if (this.transformControlsMode == "rotate") {
      this.transformControlsMode = "translate";
    } else {
      this.transformControlsMode = "rotate";
    }
    this.transformControls.forEach((tc) =>
      tc.setMode(this.transformControlsMode)
    );
    this.transformControls.forEach((tc) => console.log(tc.size));
  }

  reset() {
    let view = this.camera.view;
    this.camera.setViewOffset(
      view.fullWidth,
      view.fullHeight,
      0,
      0,
      view.fullWidth,
      view.fullHeight
    );

    this.scene.children.forEach((roof) => {
      if (roof.name == "roof") {
        roof.children[0].position.set(0, 0, 0);
        roof.children[0].rotation.set(0, 0, 0);
      }
    });
    this.transformControls.forEach((tc) => tc.update());

    this.numPlacedPanels = 0;
    this.loopOverPanels((panel, i) => {
      if (
        (this.data[i].placed = panel.children[0].visible =
          panel.children[0].visible0)
      ) {
        this.numPlacedPanels++;
      }
    });
    this.roof.numPlacedPanels = this.numPlacedPanels;
    this.render();
  }

  async saveImage($event) {
    let canvas: any = this.canvasElementRef.nativeElement;
    let gridVisible = this.materialNotPlaced.visible;
    this.materialNotPlaced.visible = false;
    this.transformControls.forEach((tc) => {
      tc.visible = false;
    });

    this.materialBox.uniforms.opacity.value = 1;
    this.materialPlaced.uniforms.opacity.value = 1;
    this.render();
    this.setMaterialBoxTransparency();
    this.transformControls.forEach((tc) => {
      tc.visible = true;
    });
    this.materialNotPlaced.visible = gridVisible;

    const a = document.createElement("a");
    a.href = canvas.toDataURL();
    a.download = "planned.png";
    a.click();
    this.render();

    const a2 = document.createElement("a");
    a2.href = await this.toDataURL(this.roof.userImageUrl);
    a2.download = "roof.png";
    a2.click();
  }

  async toDataURL(url) {
    const blob = await fetch(url).then((res) => res.blob());
    return URL.createObjectURL(blob);
  }

  async saveImageAsBlob($event) {
    let canvas: any = this.canvasElementRef.nativeElement;
    let gridVisible = this.materialNotPlaced.visible;
    this.materialNotPlaced.visible = false;
    this.transformControls.forEach((tc) => {
      tc.visible = false;
    });

    this.materialBox.uniforms.opacity.value = 1;
    this.materialPlaced.uniforms.opacity.value = 1;
    this.render();
    this.setMaterialBoxTransparency();
    this.transformControls.forEach((tc) => {
      tc.visible = true;
    });
    this.materialNotPlaced.visible = gridVisible;
    var blob = await fetch(canvas.toDataURL()).then((res) => res.blob());
    this.render();

    return blob;
  }

  toggleGrid() {
    this.materialNotPlaced.visible = !this.materialNotPlaced.visible;
    this.render();
  }

  goBack() {
    this.planningDataService.setComponentVisibility("IMAGE_UPLOAD", true);
    this.router.navigate(["image-upload"], { queryParamsHandling: "merge" });
  }

  acceptChanges() {
    this.roof.numPlacedPanels = this.numPlacedPanels;
    this.roof.panelsOrientation = this.panelsOrientationMode;
    this.roof.panelType = this.selectedPanelType.id;
    this.roof.groundGrid = this.exportData();
  }

  goToNext() {
    // //alert('Saving the data to the backend. You can download the final image by pressing the "Export As Image" button.')
    // // this.canvasElementRef.nativeElement.toBlob((blob) => {
    // // });
    // // function sendFile(file) {
    // //     var uri = "/index.php";
    // //     var xhr = new XMLHttpRequest();
    // //     var fd = new FormData();
    // //     xhr.open("POST", uri, true);
    // //     xhr.onreadystatechange = function() {
    // //         if (xhr.readyState == 4 && xhr.status == 200) {
    // //             alert(xhr.responseText); // handle response.
    // //         }
    // //     };
    // //     fd.append('myFile', file);
    // //     // Initiate a multipart/form-data upload
    // //     xhr.send(fd);
    // // }
    // try {
    //   this.saveSceneSnapshot(this.sceneType);
    //   this.roofService.savePlacement(this.canvasElementRef.nativeElement, 'placed_panels.png')
    //     .subscribe(result => {
    //       // results[0] is the uploading response
    //       // results[1] is the savePlacement response
    //       var configId = result[0].uuid;
    //       var urlPrefix = window.location.protocol + '//' + window.location.host + (window.location.port ? ':' + window.location.port : '');
    //       var fullUrl = urlPrefix + "/load?configId=" + configId;
    //       this.infoMessage = `<div>You can access or share this example using this id:</div><div><a target="_blank" href="${fullUrl}">${fullUrl}</a></div>`;
    //       this.growler.growl(this.infoMessage, GrowlerMessageType.Info);
    //       this.roofService.setShareableLink(configId);
    //     });
    // } catch (err) {
    //   console.error(err);
    //   alert('Could not save this placement. ' + err);
    // }
  }

  // this fits 3D plane to the data, creates the camera and 3D objects
  private fitPanels(w, h, h2, aspect, fov, area) {
    // 1-st, find two horizon points (horizontal and vertical intersections)
    let horizon = findHorizon(this.data, area),
      ahs = horizon.ahs,
      avs = horizon.avs;

    // 2-nd, pick the plane to place the panels in
    let focalLength = h2 / (2 * Math.tan((fov * Math.PI) / 360));
    let plane = new THREE.Plane(),
      p1 = new THREE.Vector3(),
      p2 = new THREE.Vector3(),
      p3 = new THREE.Vector3();

    p1.set(ahs.x - w / 2, h / 2 - ahs.y, 0).multiplyScalar(h2 / h);
    p1.z = -focalLength;
    p2.set(avs.x - w / 2, h / 2 - avs.y, 0).multiplyScalar(h2 / h);
    p2.z = -focalLength;

    p3.set(0, 0, 0);
    plane.setFromCoplanarPoints(p1, p2, p3);

    if (plane.normal.z < 0) {
      plane.normal.multiplyScalar(-1);
    }

    // at this point plane.normal.y should be > 0, we lower the plane by setting plane.constant to the value > 0
    plane.constant = h2 / 2;

    // create 3D object to hold the panels
    let roof = new THREE.Object3D();
    roof.name = "roof";
    roof.quaternion.setFromUnitVectors(
      new THREE.Vector3(0, 1, 0),
      plane.normal.clone()
    );
    this.scene.add(roof);

    // next, we calculate panels coordinates in the plane
    let panelsWorldCoordinates = new Array(this.data.length),
      ray = new THREE.Ray();

    let count = 0,
      minZ = h2 / 2,
      maxZ = h2 * 2;
    for (let i = 0; i < this.data.length; i++) {
      let p = this.data[i],
        pw = {};

      if (p.area != area) continue;

      for (let j = 0; j < 4; j++) {
        ray.direction
          .set(p[j][0] - w / 2, h / 2 - p[j][1], 0)
          .multiplyScalar(h2 / h);
        ray.direction.z = -focalLength;
        ray.direction.normalize();

        pw[j] = ray.intersectPlane(plane, new THREE.Vector3());

        if (pw[j]) {
          minZ = Math.min(minZ, -pw[j].z);
          maxZ = Math.max(maxZ, -pw[j].z);
          if (count == 0 || p.placed) {
            roof.position.add(pw[j]);
            count++;
          }
        }
      }

      panelsWorldCoordinates[i] = pw;
    }

    roof.position.multiplyScalar(1 / count);
    roof.updateMatrixWorld(true);

    let inversePlaneMatrix = new THREE.Matrix4();
    inversePlaneMatrix.getInverse(roof.matrix);

    let panels = new THREE.Object3D();
    roof.add(panels);

    // create the panels
    for (let i = 0; i < this.data.length; i++) {
      const p = this.data[i];
      const pi = panelsWorldCoordinates[i];

      if (p.area != area) continue;

      if (!(pi[0] && pi[1] && pi[2] && pi[3])) {
        if (p.placed) {
          console.warn("failed to create the panel:", i, p);
        }
        continue;
      }

      const panel = new THREE.Mesh(this.geometry, this.materialNotPlaced);

      const placed = this.makePlacedBox();
      panel.add(placed);
      placed.visible = p.placed;
      placed.visible0 = p.placed;
      panel.name = i.toString();
      panel.area = area;
      this.areas[area] = true;

      // position
      for (let j = 0; j < 4; j++) {
        panel.position.add(pi[j]);
      }

      panel.position.multiplyScalar(0.25);

      // rotation (+ position)
      panel.matrix.set(
        0.5 * (pi[0].x - pi[1].x + pi[3].x - pi[2].x),
        0.5 * (pi[3].x - pi[0].x + pi[2].x - pi[1].x),
        plane.normal.x * this.PANEL_HEIGHT,
        panel.position.x,
        0.5 * (pi[0].y - pi[1].y + pi[3].y - pi[2].y),
        0.5 * (pi[3].y - pi[0].y + pi[2].y - pi[1].y),
        plane.normal.y * this.PANEL_HEIGHT,
        panel.position.y,
        0.5 * (pi[0].z - pi[1].z + pi[3].z - pi[2].z),
        0.5 * (pi[3].z - pi[0].z + pi[2].z - pi[1].z),
        plane.normal.z * this.PANEL_HEIGHT,
        panel.position.z,
        0,
        0,
        0,
        1
      );
      //
      panel.matrix.premultiply(inversePlaneMatrix);
      panel.matrixAutoUpdate = false;

      // done
      panels.add(panel);
    }

    // finally, create the camera
    if (!this.camera) {
      this.camera = new THREE.PerspectiveCamera(
        fov,
        aspect,
        minZ / 2,
        maxZ * 2
      );
      this.camera.setViewOffset(h2 * aspect, h2, 0, 0, h2 * aspect, h2);
    }
    this.scene.add(this.camera);

    return roof;
  }

  // this makes the texture for non-placed panel cell in the grid
  private makeBorderTexture(channel: number): THREE.DataTexture {
    let width = 16,
      height = 16;

    let size = width * height;
    let data = new Uint8Array(size * 4);

    for (let i = 0; i < size; i++) {
      let x = i % width,
        y = (i / width) | 0;

      let stride = i * 4;

      data[stride + channel] = 255;
      data[stride + 3] =
        x > 0 && x < width - 1 && y > 0 && y < height - 1 ? 0 : 120;
    }

    let texture = new THREE.DataTexture(data, width, height, THREE.RGBAFormat);
    texture.magFilter = THREE.LinearFilter;
    texture.needsUpdate = true;
    return texture;
  }

  // this creates unscaled solar panel box
  private makePlacedBox(): THREE.Mesh {
    let placed = new THREE.Mesh(this.geometryBox, this.materialBox);
    placed.raycast = (raycaster: THREE.Raycaster, intersects: any) => {};
    placed.position.z = 0.5;

    let placedUpside = new THREE.Mesh(this.geometry, this.materialPlaced);
    placedUpside.raycast = (raycaster: THREE.Raycaster, intersects: any) => {};
    placedUpside.position.z = 0.5;
    placedUpside.scale.setScalar(1 - 0.01 * this.FRAME_WIDTH);
    placed.add(placedUpside);

    return placed;
  }

  // this sets this.data from original data
  private processData(data, w, h) {
    let offset = this.dataOffsetX;
    let isPointInvalid = (p): boolean => {
      // there is some crazy data coming. Discarding irrational values.
      return (
        p[0] - offset < -0.2 * w ||
        p[0] - offset > w * 1.2 ||
        p[1] < 0 ||
        p[1] > h
      );
    };

    let convertPoint = (p) => {
      return {
        "0": p[0] - offset,
        "1": p[1],
      };
    };

    this.data = [];

    let dropped = 0,
      placed = 0,
      not_placed = 0;

    for (let i = 0; i < data.placed.length; i++) {
      if (
        isPointInvalid(data.placed[i][0]) ||
        isPointInvalid(data.placed[i][1]) ||
        isPointInvalid(data.placed[i][2]) ||
        isPointInvalid(data.placed[i][3])
      ) {
        dropped++;
        continue;
      }

      this.data.push({
        placed: true,
        area: data.placed[i][4] || 0,
        "0": convertPoint(data.placed[i][0]),
        "1": convertPoint(data.placed[i][1]),
        "2": convertPoint(data.placed[i][2]),
        "3": convertPoint(data.placed[i][3]),
      });
      placed++;
    }

    for (let i = 0; i < data.not_placed.length; i++) {
      if (
        isPointInvalid(data.not_placed[i][0]) ||
        isPointInvalid(data.not_placed[i][1]) ||
        isPointInvalid(data.not_placed[i][2]) ||
        isPointInvalid(data.not_placed[i][3])
      ) {
        dropped++;
        continue;
      }

      this.data.push({
        placed: false,
        area: data.not_placed[i][4] || 0,
        "0": convertPoint(data.not_placed[i][0]),
        "1": convertPoint(data.not_placed[i][1]),
        "2": convertPoint(data.not_placed[i][2]),
        "3": convertPoint(data.not_placed[i][3]),
      });
      not_placed++;
    }

    /*
      "We have " +
        placed +
        " placed and " +
        not_placed +
        " not placed, dropped " +
        dropped +
        " invalid panels",

        this.data
    );
    */
  }

  loopOverPanels(f) {
    for (let i = 0; i < this.data.length; i++) {
      let panel = this.scene.getObjectByName(i.toString());

      // when fitPanels(...) fails to calculate panelsWorldCoordinates[i] for this.data[i]
      // panel variable will be undefined here - we have no choice but to skip it
      if (!panel) continue;

      f(panel, i);
    }
  }

  // this converts this.data to original representation
  exportData(): any {
    let result = { placed: [], not_placed: [] };

    let vector = new THREE.Vector3(),
      vertexCoordinates = [
        { x: 0.5, y: -0.5 },
        { x: -0.5, y: -0.5 },
        { x: -0.5, y: 0.5 },
        { x: 0.5, y: 0.5 },
      ];

    let w = this.canvasElementRef.nativeElement.clientWidth;
    let h = this.canvasElementRef.nativeElement.clientHeight;

    // this.PADDING % of w corresponds to dataOffsetX

    let scale = 1;
    var offsetFromPadding = 0.01 * this.PADDING * w;
    if (offsetFromPadding > 0) {
      scale = this.dataOffsetX / (0.01 * this.PADDING * w);
    }

    w *= scale;
    h *= scale;

    this.camera.view.enabled = false;
    this.camera.updateProjectionMatrix();
    this.render();

    this.loopOverPanels((panel, i) => {
      let vertices = new Array(5);
      for (let j = 0; j < 4; j++) {
        vector.set(vertexCoordinates[j].x, vertexCoordinates[j].y, 0);
        panel.localToWorld(vector);
        vector.project(this.camera);

        vertices[j] = [
          ((vector.x + 1) * w) / 2 + this.dataOffsetX,
          ((1 - vector.y) * h) / 2,
        ];
      }
      vertices[4] = panel.area;
      /*
      //debug (but 'original' data has x offset removed)
      vertices.original = this.data[i]
      */
      const toPlace = [vertices[0], vertices[1], vertices[2], vertices[3]];
      if (this.data[i].placed) {
        result.placed.push(toPlace);
      } else {
        result.not_placed.push(toPlace);
      }
    });

    this.camera.view.enabled = true;
    this.camera.updateProjectionMatrix();
    this.render();

    result["mask"] = this.maskingPolygons.map(function (array) {
      return array.slice();
    });
    result["zoom"] = Object.assign({}, this.camera.view);
    this.roof.groundGrid = result;

    return result;
  }

  onPanelTextureChange($event) {
    var frameColorStr =
      "rgb(" + this.selectedPanelType.frame_rgb.join(",") + ")";
    this.changePanelsLook(this.selectedPanelType.panel, frameColorStr, 3);
    if (this.selectedPanelType && this.selectedPanelType.id) {
      this.roof.panelType = this.selectedPanelType.id;
    }
  }

  // the method could take as input the URL of the texture image, the frame colour and frame percentage size
  changePanelsLook(url: string, color: string, frameWidth) {
    this.PANEL_IMAGE = url;
    this.FRAME_COLOR = color;
    this.FRAME_WIDTH = frameWidth;

    if (this.materialBox) {
      this.materialBox.color = new THREE.Color(color);
      let loader = new THREE.TextureLoader();
      this.materialPlaced.uniforms.map.value = loader.load(url, () => {
        this.render();
      });

      this.scene.traverse((object: any) => {
        if (object.material == this.materialPlaced) {
          object.scale.setScalar(1 - 0.01 * this.FRAME_WIDTH);
        }
      });
    }
  }

  async onChangeRatio() {
    try {
      this.roof.panelHeightRatio = parseFloat(this.panelHeightRatio);
      this.roof.panelWidthRatio = parseFloat(this.panelWidthRatio);

      const gridData =
        await this.planningDataService.submitInfoForPlacementOnUserImage(
          this.roof
        );
      this.roof.groundGrid = gridData.grid;
      this.ngOnInit();
    } catch (e) {
      alert(`An error has ocurred: ${e.message}`);
    }
  }

  async togglePanelsOrientation() {
    if (
      !this.panelsOrientationMode ||
      this.panelsOrientationMode === "vertical"
    ) {
      this.panelsOrientationMode = "horizontal";
    } else if (this.panelsOrientationMode === "horizontal") {
      this.panelsOrientationMode = "vertical";
    }

    this.roof.panelsOrientation = this.panelsOrientationMode;

    // Call the panels placing method
    const gridData =
      await this.planningDataService.submitInfoForPlacementOnUserImage(
        this.roof
      );
    this.roof.groundGrid = gridData.grid;
    this.ngOnInit();
  }

  toNumberString(val: any, defaultVal: string = "1") {
    if (!val) return defaultVal;
    return val.toString();
  }

  isNumeric(str: any) {
    return !isNaN(str) && !isNaN(parseFloat(str));
  }

  onPanelWidthRatioChange(value: string) {
    if (!this.isNumeric(value)) {
      alert("Please enter a numeric value");
      this.panelWidthRatio = this.toNumberString(this.roof.panelWidthRatio);
    } else {
      this.roof.panelWidthRatio = parseFloat(value);
    }
  }

  onPanelHeightRatioChange(value: string) {
    if (!this.isNumeric(value)) {
      alert("Please enter a numeric value");
      this.panelHeightRatio = this.toNumberString(this.roof.panelHeightRatio);
    } else {
      this.roof.panelHeightRatio = parseFloat(value);
    }
  }

  onHorizontalTileCountChange(value: string) {
    if (value.length === 0) {
      this.roof.horizontalTileCount = undefined;
      return;
    }

    const count = Number.parseInt(value);
    if (!Number.isInteger(count)) {
      alert("Please enter a numeric value");
      this.horizontalTileCount = this.roof.horizontalTileCount.toString();
    } else {
      this.roof.horizontalTileCount = count;
    }
  }

  onVerticalTileCountChange(value: string) {
    if (value.length === 0) {
      this.roof.verticalTileCount = undefined;
      console.log("reset vertical tile count");
      return;
    }

    const count = Number.parseInt(value);
    if (!Number.isInteger(count)) {
      alert("Please enter a numeric value");
      this.verticalTileCount = this.roof.verticalTileCount.toString();
    } else {
      console.log("set vertical tile count to", count);
      this.roof.verticalTileCount = count;
    }
  }

  onModifySideLength() {
    const modalContent: IModalContent = {
      header: "Modify the Side Length",
      body:
        'If you have more accurate information about the side length, you can modify it here.<form><div class="form-group medium-space"><div class="text-field-middle"><input type="text" class="form-control" id="updated-side-length_' +
        this.roofIndex +
        '" value="' +
        this.sideLengthMeters +
        '"></div></div></form>',
      cancelButtonText: "Cancel",
      OKButtonText: "OK",
    };
    this.modalService.show(modalContent).then(async (success) => {
      if (success) {
        const nativeElem = this.elRef.nativeElement.parentElement;
        const selector = `#updated-side-length_${this.roofIndex}`;
        var newSideLength = nativeElem.querySelector(selector).value;
        this.roof.sideLength = parseFloat(newSideLength);

        // TODO: Call the API to reposition the panels
        // Call the panels placing method
        if (this.sceneType === "GROUND_IMAGE") {
          const gridData =
            await this.planningDataService.submitInfoForPlacementOnUserImage(
              this.roof
            );
          this.roof.groundGrid = gridData.grid;
          if (gridData.azimuth) {
            this.roof.azimuth = gridData.azimuth;
          }
          this.ngOnInit();
        } else {
          const gridData =
            await this.planningDataService.submitInfoForPlacementOnSatellite(
              this.roof
            );
          this.roof.satelliteGrid = gridData.grid;
          this.ngOnInit();
        }
      }
    });
  }

  onToggleSideLength() {
    this.shouldShowSideLength = !this.shouldShowSideLength;
    if (this.shouldShowSideLength) {
      this.sideLengthShowHideLabel = "Hide";
    } else {
      this.sideLengthShowHideLabel = "Show";
    }
    return this.shouldShowSideLength;
  }

  removeAllPanels() {
    this.loopOverPanels((panel, i) => {
      panel.children[0].visible = false;
      this.data[i].placed = false;
    });

    this.roof.numPlacedPanels = 0;
    this.numPlacedPanels = 0;
    this.render();
  }

  onMaskingMode() {
    this.maskingMode = true;
    this.mainButtonsMode = false;
    this.materialBox.uniforms.opacity.value = 0.3;
    this.materialPlaced.uniforms.opacity.value = 0.3;
    this.transformControls.forEach((tc) => {
      tc.visible = false;
    });
    this.render();
  }

  updateMaskingTexture() {
    let mask = this.drawMaskingPolygons(false);
    maskingTexture.image = mask;
    maskingTexture.size.set(mask.width, mask.height);
    maskingTexture.needsUpdate = true;
  }

  onDoneMasking() {
    this.maskingMode = false;
    this.mainButtonsMode = true;
    this.setMaterialBoxTransparency();

    // overwrite maskingPolygons
    this.maskingPolygons.length = this.maskingPolygonsCopy.length;
    for (let i = 0; i < this.maskingPolygonsCopy.length; i++) {
      this.maskingPolygons[i] = this.maskingPolygonsCopy[i].slice();
    }

    // update
    this.updateMaskingTexture();
    this.transformControls.forEach((tc) => {
      tc.visible = true;
    });
    this.render();
  }

  onCancelMasking() {
    this.maskingMode = false;
    this.mainButtonsMode = true;
    this.setMaterialBoxTransparency();

    // overwrite maskingPolygonsCopy
    this.maskingPolygonsCopy.length = this.maskingPolygons.length;
    for (let i = 0; i < this.maskingPolygons.length; i++) {
      this.maskingPolygonsCopy[i] = this.maskingPolygons[i].slice();
    }

    this.transformControls.forEach((tc) => {
      tc.visible = true;
    });
    this.render();
  }

  onAreasSplittingMode() {
    this.areasSplittingMode = true;
    this.mainButtonsMode = false;
    this.materialBox.uniforms.opacity.value = 0.3;
    this.materialPlaced.uniforms.opacity.value = 0.3;
    this.transformControls.forEach((tc) => {
      tc.visible = false;
    });
    this.render();
  }

  setMaterialBoxTransparency() {
    this.materialBox.uniforms.opacity.value = this.transparencyOn ? 0.3 : 1;
    this.materialPlaced.uniforms.opacity.value = this.transparencyOn ? 0.3 : 1;
  }

  onCancelAreasSplitting() {
    this.areasSplittingMode = false;
    this.mainButtonsMode = true;

    this.setMaterialBoxTransparency();

    this.scene.traverse((mesh) => {
      if (mesh.material == this.materialSelectedToSplit) {
        mesh.material = this.materialNotPlaced;
      }
    });

    this.transformControls.forEach((tc) => {
      tc.visible = true;
    });
    this.render();
  }

  onSplitArea() {
    this.areasSplittingMode = false;
    this.mainButtonsMode = true;

    this.setMaterialBoxTransparency();

    let selected = [];
    this.scene.traverse((mesh) => {
      if (mesh.material == this.materialSelectedToSplit) {
        mesh.material = this.materialNotPlaced;
        // collect selected meshes
        selected.push(mesh);
      }
    });

    if (selected.length) {
      // check if seleted meshes have the same parent
      // if they don't, we have no choie but to abort
      if (
        selected.reduce(function (sameParent, object) {
          return sameParent && object.parent === selected[0].parent;
        }, true)
      ) {
        let roof = new THREE.Object3D();
        roof.name = "roof";
        roof.position.copy(selected[0].parent.parent.position);
        roof.quaternion.copy(selected[0].parent.parent.quaternion);
        this.scene.add(roof);

        let panels = new THREE.Object3D();
        panels.position.copy(selected[0].parent.position);
        panels.quaternion.copy(selected[0].parent.quaternion);
        roof.add(panels);

        let nextArea = this.getNextArea();

        selected.forEach(function (object) {
          object.area = nextArea;
          panels.add(object);
        });

        // re-center the cells, or the transform controls would end up in the same spot
        roof.updateMatrixWorld(true);

        // but first, clear any transform we have on panels
        selected.forEach(function (object) {
          object.applyMatrix(panels.matrix);
        });
        panels.position.setScalar(0);
        panels.rotation.set(0, 0, 0);

        let box = new THREE.Box3();
        box.setFromObject(roof);
        let vec = new THREE.Vector3();
        box.getCenter(vec);

        let tmp = vec.clone();
        roof.worldToLocal(tmp);
        roof.position.copy(vec);
        selected.forEach(function (object) {
          // these have matrixAutoUpdate = false, so move them by hand
          object.matrix.elements[12] -= tmp.x;
          object.matrix.elements[13] -= tmp.y;
          object.matrix.elements[14] -= tmp.z;
        });

        // update everything
        roof.updateMatrixWorld(true);

        let tc = new (window as any).THREE.TransformControls(
          this.camera,
          this.canvas
        );
        tc.setSpace("local");
        tc.attach(panels);
        tc.addEventListener("change", () => {
          this.render();
        });
        this.scene.add(tc);

        this.transformControls.push(tc);
      } else {
        alert(
          "Seleted cells belong to different areas - please select cells from single area at a time"
        );
      }
    }

    this.transformControls.forEach((tc) => {
      tc.visible = true;
    });
    this.render();
  }

  toggleTransparency() {
    this.transparencyOn = !this.transparencyOn;

    this.setMaterialBoxTransparency();

    this.render();
  }
}
