import roundUp from "./roundUp";

export enum Units {
  Meters,
  Tiles,
}

export enum Orientation {
  Horizontal,
  Vertical,
}

export const availableUnits = [
  { label: "Tiles", unit: Units.Tiles },
  { label: "Meters", unit: Units.Meters },
];

export function metersToTiles(x: number, deckFactor: number) {
  return roundUp(x / (deckFactor / 100), 1);
}

export type UnitConverter = (n: number, orientation: Orientation) => number;

export function createUnitConverter(
  unit: Units,
  deckBreite: number,
  deckLaenge: number
) {
  return (n: number, orientation: Orientation) => {
    if (unit === Units.Meters) {
      return roundUp(n, 2);
    }

    const deckFactor =
      orientation === Orientation.Horizontal ? deckBreite : deckLaenge;

    return metersToTiles(n, deckFactor);
  };
}

export function toUnitString(unit: Units) {
  return unit === Units.Meters ? "m" : "Ziegel";
}
