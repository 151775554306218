export const moduleTypes = [
  {
    name: "Longi LR4-60HiH 370M",
    productSku: "EP_242",
    weight: 19.5,
    length: 1755,
    width: 1038,
    thickness: 35,
    mounting: 1.14,
  },
  {
    name: "GCL-M8/60H 380",
    productSku: "EP_242",
    weight: 20.9,
    length: 1766,
    width: 1048,
    thickness: 35,
    mounting: 1.14,
  },
  {
    name: "GCL-M8/60H 370",
    productSku: "EP_242",
    weight: 20.9,
    length: 1766,
    width: 1048,
    thickness: 35,
    mounting: 1.14,
  },
  {
    name: "JA Solar JAM60S20 380/MR",
    productSku: "EP_242",
    weight: 20.5,
    length: 1769,
    width: 1048,
    thickness: 35,
    mounting: 1.14,
  },
  {
    name: "JA Solar JAM54S30 410/MR",
    productSku: "EP_249",
    weight: 21.5,
    length: 1722,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
  {
    name: "JA Solar JAM60S20 385/MR",
    productSku: null,
    weight: 0,
    length: 1777,
    width: 1052,
    thickness: 0,
    mounting: 1.1395,
  },
  {
    name: "GCL-M10/54H 410",
    productSku: null,
    weight: 0,
    length: 1722,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
  {
    name: "Longi LR5-54HiH 410M",
    productSku: null,
    weight: 0,
    length: 1722,
    width: 1134,
    thickness: 30,
    mounting: 1.255,
  },
  {
    name: "Other",
    productSku: null,
    weight: 19.5,
    length: 1755,
    width: 1038,
    thickness: 35,
    mounting: 1.14,
  },
];

export type ModuleType = (typeof moduleTypes)[number];
