import { checkIfCornersChanged } from "../../../shared/utils";
import {
  CORNER_POINT_RADIUS,
  ANCHOR_POINT_RADIUS,
} from "../../../shared/constants";
import { PlanningDataService } from "../../../core/services/planning-data.service";
import { Router, Params } from "@angular/router";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
  Input,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { HostListener } from "@angular/core";
import { Project, ProjectRoof } from "../../../core/models/projectModel";
declare var fabric: any;

@Component({
  styleUrls: ["area-corners-selection-canvas.component.css"],
  selector: "cm-area-corners-selection-canvas",
  templateUrl: "./area-corners-selection-canvas.component.html",
})
export class AreaCornersSelectionCanvasComponent implements OnInit {
  public staticImg: string;
  private selectedAreas: any;
  private selectedAreasCorners: any[] = [];
  private initialAreasCorners: any[] = [];
  private areAreaCornersModified: boolean;
  private canvas: any;
  private numCorners = 0;
  private mouse = { x: 0, y: 0 };
  private project: Project;

  @Input() fullComponentVersion: boolean = true;
  @Input() roof: ProjectRoof;

  POLYGON_COLOR = {
    roof: "0,0,255",
    obstacle: "255,0,0",
    dormer: "0,120,255",
    corner: "255,0,0",
  };

  @ViewChild("canvasWrapperAreaCorners")
  public canvasWrapperElementRef: ElementRef;
  @ViewChild("canvasAreaCorners") public canvasElementRef: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planningDataService: PlanningDataService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.project = data.project;
      this.selectedAreasCorners = this.roof.selectedAreasCorners || [];

      const sizes = this.project.addressCenter.config.size
        .split(/x/g)
        .map((s) => parseInt(s, 10));
      this.canvasElementRef.nativeElement.width = sizes[0];
      this.canvasElementRef.nativeElement.height = sizes[1];

      this.route.queryParams.subscribe((params: Params) => {});
      this.initCanvas();
    });
  }

  ngOnDestroy() {}

  initCanvas() {
    const that = this;
    this.canvas = new fabric.Canvas(this.canvasElementRef.nativeElement);
    this.setSatelliteImageBg();

    // Display selected areas
    this.selectedAreas = this.roof.selectedAreas;
    this.displayCorners();

    this.canvas.on("mouse:over", function (e) {
      if (e.target && e.target.type && e.target.type === "roof") {
        let color = "rgba(" + that.POLYGON_COLOR["roof"] + ",0.1)";
        e.target.set("fill", color);
        that.canvas.renderAll();
      }
    });

    this.canvas.on("mouse:out", function (e) {
      if (e.target && e.target.type && e.target.type === "roof") {
        let color = "rgba(" + that.POLYGON_COLOR["roof"] + ",0.4)";
        e.target.set("fill", color);
        that.canvas.renderAll();
      }
    });

    // Add the mouse listener for the corners
    fabric.util.addListener(
      this.canvas.upperCanvasEl,
      "dblclick",
      function (event) {
        if (that.numCorners > 3) {
          return;
        }
        const currPoint = that.canvas.getPointer(event);
        that.numCorners++;

        let fill = "red";
        if (that.numCorners === 1) {
          fill = "yellow";
        } else if (that.numCorners === 2) {
          fill = "#0eff42";
        } else if (that.numCorners === 3) {
          fill = "magenta";
        }

        var circle = that.createCornerCircle(
          {
            x: currPoint.x,
            y: currPoint.y,
          },
          { name: that.numCorners, fill: fill, modified: true }
        );

        that.selectedAreasCorners.push({
          x: currPoint.x,
          y: currPoint.y,
          modified: true,
        });

        that.canvas.add(circle);

        if (that.selectedAreasCorners.length === 4) {
          that.updateSideLength();
        }
      }
    );

    fabric.util.addListener(
      this.canvas.upperCanvasEl,
      "mouseup",
      function (event) {
        var activeObj = that.canvas.getActiveObject();
        if (activeObj && activeObj.type && activeObj.type === "corner") {
          that.updateSideLength();
        }
      }
    );

    fabric.util.addListener(this.canvas.upperCanvasEl, "wheel", (event) => {
      this.mouse = this.getMousePosition(event);
      event.preventDefault();
      if (event.deltaY < 0) this.zoomIn();
      if (event.deltaY > 0) this.zoomOut();
    });
  }

  setSatelliteImageBg() {
    var that = this;
    this.staticImg = this.planningDataService.getStaticMapImage(this.project);

    var bgImgUrl = this.staticImg;
    fabric.Image.fromURL(bgImgUrl, (bgImg) => {
      that.canvas.setBackgroundImage(
        bgImg,
        that.canvas.renderAll.bind(that.canvas),
        {}
      );
    });
  }

  getMousePosition(event: MouseEvent) {
    var rect = this.canvasElementRef.nativeElement.getBoundingClientRect();
    return {
      x: (event.clientX - rect.left) / this.canvas.getZoom(),
      y: (event.clientY - rect.top) / this.canvas.getZoom(),
    };
  }

  createCornerCircle(position, options?) {
    var fill = "red";
    if (options.fill) {
      fill = options.fill;
    }

    var config = {
      type: "corner",
      left: position.x,
      top: position.y,
      radius: CORNER_POINT_RADIUS,
      stroke: fill,
      strokeWidth: 2,
      fill: "rgba(0,0,0,0)",
      originX: "center",
      originY: "center",
      centeredRotation: true,
      cornerStyle: "circle",
      cornerSize: 5,
      cornerColor: "green",
      padding: 3,
      hasControls: false,
      hasBorders: true,
    };

    if (options) {
      if (options.name) {
        config["name"] = options.name;
      }

      if (options.shapeGroup) {
        config["shapeGroup"] = options.shapeGroup;
      }

      if (options.index) {
        config["index"] = options.index;
      }

      if (options.modified) {
        config["modified"] = options.modified;
      }
    }

    fabric.cross;
    var circle = new fabric.Circle(config);

    return circle;
  }

  @HostListener("document:keypress", ["$event"])
  handleKeyboardEvent(event: KeyboardEvent) {
    this.handleKeypress(event);
  }

  handleKeypress($event) {
    var keyPressed = $event.key;
    if (keyPressed === "Delete") {
      this.handleCanvasDelete();
    }
  }

  handleCanvasDelete() {
    // Will delete the active Object
    var activeObj = this.canvas.getActiveObject();
    if (activeObj.type && activeObj.type === "corner") {
      this.numCorners--;
    }
    this.canvas.remove(activeObj);
  }

  // TODO: Can be factored out into utilities
  displayAreas(areas, canvas, options?) {
    // TODO: Display the areas on the canvas
    for (let i = 0; areas && i < areas.length; i++) {
      let color = "rgba(" + this.POLYGON_COLOR[areas[i].type] + ",0.4)";

      this.drawPolygon(
        areas[i].points,
        areas[i].type,
        color,
        true,
        areas[i].parent > -1
      );
    }
  }

  private drawPolygon(vertices, type, fillColor, selected, hole) {
    // Each area will be represented by a Polygon

    var polygonPoints = [];
    _forEach(vertices, (point, anchorIndex) => {
      polygonPoints.push({ x: point[0], y: point[1] });
    });

    var polygon = new fabric.Polygon(polygonPoints, {
      name: `${type}_${1}`,
      type: type,
      angle: 0,
      fill: hole ? "transparent" : fillColor,
      stroke: hole ? "yellow" : undefined,
      strokeDashArray: hole ? [5, 5] : undefined,
      opacity: 0.6,
      hasControls: false,
      selectable: false,
      hoverCursor: "default",
    });

    this.canvas.add(polygon);
    polygon.prevLeft = polygon.get("left");
    polygon.prevTop = polygon.get("top");
  }

  displayCorners() {
    let that = this;

    _forEach(this.selectedAreasCorners, (point, index) => {
      that.numCorners++;

      let fill = "red";
      if (index === 0) {
        fill = "yellow";
      } else if (index === 1) {
        fill = "#0eff42";
      } else if (index === 2) {
        fill = "magenta";
      }
      var circle = that.createCornerCircle(
        {
          x: point.x,
          y: point.y,
        },
        { name: that.numCorners, fill: fill }
      );

      that.canvas.add(circle);
    });
  }

  zoomInOut(out) {
    let ivpt = fabric.util.invertTransform(this.canvas.viewportTransform);

    let zoom = ivpt[0];
    let offsetX = ivpt[4];
    let offsetY = ivpt[5];

    let localX =
      this.mouse.x * this.canvas.viewportTransform[0] * zoom + offsetX;
    let localY =
      this.mouse.y * this.canvas.viewportTransform[0] * zoom + offsetY;

    let newZoom = out ? Math.min(1, zoom * 1.1) : Math.max(1e-2, zoom / 1.1);

    let newWidth = newZoom * this.canvas.getWidth();
    let newHeight = newZoom * this.canvas.getHeight();

    let newOffsetX = localX - ((localX - offsetX) * newZoom) / zoom;
    let newOffsetY = localY - ((localY - offsetY) * newZoom) / zoom;

    ivpt[4] = Math.max(
      0,
      Math.min(this.canvas.getWidth() - newWidth, newOffsetX)
    );
    ivpt[5] = Math.max(
      0,
      Math.min(this.canvas.getHeight() - newHeight, newOffsetY)
    );
    ivpt[0] = ivpt[3] = newZoom;

    this.canvas.setViewportTransform(fabric.util.invertTransform(ivpt));
  }

  zoomIn() {
    this.zoomInOut(false);
  }

  zoomOut() {
    this.zoomInOut(true);
  }

  async updateSideLength() {
    var cornersArray = this.getCorners();
    if (cornersArray.length >= 2) {
      this.roof.selectedAreasCorners = cornersArray;

      try {
        const sideLengthInfo = await this.planningDataService.updateSideLength(
          this.project.addressCenter.center.latitude,
          this.project.addressCenter.center.longitude,
          this.roof
        );
        this.roof.sideLength = sideLengthInfo.sideLengthMeters;
      } catch (e) {
        console.error(e);
        alert("Could not update the sideLength! Please try again later.");
      }
    }
  }

  async acceptChanges(): Promise<boolean> {
    const cornersArray = this.getCorners();
    if (cornersArray.length === 4) {
      this.roof.selectedAreasCorners = cornersArray;
      return true;
    } else {
      alert("Please select exactly 4 corners for each area!");
      return false;
    }
  }

  getCorners() {
    var allCornerObjects = _filter(this.canvas.getObjects(), (obj) => {
      return obj.type === "corner";
    });

    var cornersArray = [];
    _forEach(allCornerObjects, (cornerCircle) => {
      var corner = {
        x: cornerCircle.left,
        y: cornerCircle.top,
        name: cornerCircle.name,
      };
      if (cornerCircle.modified) {
        corner["modified"] = cornerCircle.modified;
      }
      cornersArray.push(corner);
    });
    return cornersArray;
  }
}
