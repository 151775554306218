import { AreaCornersSelectionComponent } from "./../area-corners-selection/area-corners-selection.component";
import { ImageCornersSelectionComponent } from "../image-corners-selection/image-corners-selection.component";
import { PlanningDataService } from "./../../core/services/planning-data.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  Component,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import _forEach from "lodash/forEach";
import _find from "lodash/find";
import _filter from "lodash/filter";
import { DropzoneComponent } from "ngx-dropzone-wrapper";
import { ImageUploadRoofComponent } from "./image-upload-roof/image-upload-roof.component";
import { Project } from "../../core/models/projectModel";

@Component({
  styleUrls: ["./image-upload.component.css"],
  selector: "cm-image-upload",
  templateUrl: "./image-upload.component.html",
})
export class ImageUploadComponent implements OnInit {
  config: any;
  uploadedImageUrl: string;
  canSelectCorners = false;
  allowGroundGridTesting = false;
  sideLengthMeters;
  shouldShowSideLength: boolean = false;
  sideLengthShowHideLabel: string = "Show";
  cornersLoaded = false;
  orderNumber: string;
  projectId: string;
  project: Project;

  @ViewChild("imageCornersComponent")
  public imageCornersComponent: ImageCornersSelectionComponent;
  @ViewChild("satelliteCornersComponent")
  public satelliteCornersComponent: AreaCornersSelectionComponent;
  @ViewChild(DropzoneComponent)
  @ViewChildren("imageUploadRoofComponent")
  imageUploadComponents: QueryList<ImageUploadRoofComponent>;

  componentRef?: DropzoneComponent;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    protected planningDataService: PlanningDataService
  ) {}

  ngOnInit() {
    this.route.data.subscribe((data) => {
      this.project = data.project;
      this.orderNumber = this.route.snapshot.paramMap.get("orderNumber");
      this.projectId = this.route.snapshot.paramMap.get("projectId");
    });
  }

  goBack() {
    this.router.navigate(
      ["/projects", this.orderNumber, this.projectId, "area-corners"],
      { queryParamsHandling: "merge" }
    );
  }

  goToProjects() {
    this.router.navigate(["/projects", this.orderNumber]);
  }

  async onGoToNext() {
    try {
      if (!this.project.roofs.every((r) => r.userImageCorners.length === 4)) {
        alert(
          'Please configure the grid on every image. You must select an image, crop it and click on "Display Grid"'
        );
        return;
      }

      for (const component of this.imageUploadComponents.toArray()) {
        await component.acceptChanges();
      }

      this.planningDataService.persist(this.project);
      this.router.navigate(
        [`/projects/${this.orderNumber}/${this.projectId}/panel-placing`],
        { queryParamsHandling: "merge" }
      );
    } catch (e) {
      alert("An error has occurred: " + e);
      console.error(e);
    }
  }
}
