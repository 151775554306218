import { throwError as observableThrowError, Observable, from } from "rxjs";
import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpParams,
  HttpResponse,
} from "@angular/common/http";
import { LoadingService } from "../services/loading.service";
import { catchError, map, switchMap, tap } from "rxjs/operators";
import { MsalService } from "@azure/msal-angular";
import { environment } from "../../../environments/environment";
import {
  BrowserAuthError,
  InteractionRequiredAuthError,
} from "@azure/msal-browser";

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(
    private loadingService: LoadingService,
    private msal: MsalService
  ) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // users complained about showing the loading screen on this method, since it runs too fast and makes the screen flicker.
    if (!request.url.includes("v1/engine/sidelength")) {
      this.loadingService.setLoading(true, request.url);
    }

    const account = this.msal.instance.getAllAccounts()[0];
    const accessTokenRequest = {
      scopes: ["openid", environment.auth.b2cAppClientId],
      account: account,
    };

    return from(this.msal.instance.acquireTokenSilent(accessTokenRequest)).pipe(
      switchMap((token) => {
        request = request.clone({
          setHeaders: {
            Authorization: `Bearer ${token.idToken}`,
          },
          params: (request.params ? request.params : new HttpParams()).set(
            "cache-bust",
            new Date().getTime().toString()
          ),
        });

        return next
          .handle(request)
          .pipe(
            catchError((err) => {
              this.loadingService.setLoading(false, request.url);
              let errToThrow = err;
              if (err.error) {
                // Detailed error messages can come on the .error property. If it is present, display the backend ExceptionMessage property.
                errToThrow = new Error(err.error.ExceptionMessage);
              }
              return observableThrowError(errToThrow);
            })
          )
          .pipe(
            map<HttpEvent<any>, any>((evt: HttpEvent<any>) => {
              if (evt instanceof HttpResponse) {
                this.loadingService.setLoading(false, request.url);
              }
              return evt;
            })
          );
      }),
      catchError((error) => {
        this.loadingService.setLoading(false, request.url);
        if (
          error instanceof InteractionRequiredAuthError ||
          error instanceof BrowserAuthError
        ) {
          return this.msal.acquireTokenRedirect(accessTokenRequest);
        }
        return observableThrowError(error);
      })
    );
  }
}
