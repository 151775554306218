import { throwError as observableThrowError, Observable } from "rxjs";
import { Injectable, EventEmitter, Output } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { map, catchError } from "rxjs/operators";

import { environment as env } from "../../../environments/environment";

import * as constants from "../../shared/constants";

@Injectable()
export class AuthService {
  AUTH_URL: string = env.backend.api.authURL;
  isAuthenticated: boolean = false;
  @Output() authChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  constructor(private http: HttpClient) {
    let currentUser = JSON.parse(
      localStorage.getItem(constants.LOCAL_STORAGE_KEYS.USER)
    );
    this.isAuthenticated = typeof currentUser !== "undefined";
  }

  login(email: string, password: string) {
    return this.http
      .post<any>(this.AUTH_URL + "/login", { email: email, password: password })
      .pipe(
        map((user) => {
          // login successful if there's a jwt token in the response
          //if (user && user.token) {
          if (user) {
            this.isAuthenticated = typeof user !== "undefined";
            this.userAuthChanged(this.isAuthenticated);
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem(
              constants.LOCAL_STORAGE_KEYS.USER,
              JSON.stringify(user)
            );
          }

          return user;
        })
      );
  }

  private userAuthChanged(status: boolean) {
    this.authChanged.emit(status); //Raise changed event
  }

  logout(): Observable<string> {
    return this.http.post<boolean>(this.AUTH_URL + "/logout", null).pipe(
      map((loggedOut) => {
        this.isAuthenticated = !loggedOut;
        this.userAuthChanged(!loggedOut); //Return loggedIn status
        return status;
      }),
      catchError(this.handleError)
    );
  }

  private handleError(error: HttpErrorResponse) {
    console.error("server error:", error);
    if (error.error instanceof Error) {
      let errMessage = error.error.message;
      return observableThrowError(errMessage);
      // Use the following instead if using lite-server
      //return Observable.throw(err.text() || 'backend server error');
    }
    return observableThrowError(error || "Server error");
  }
}
