import { PlanningDataService } from "./core/services/planning-data.service";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ActivatedRoute, NavigationEnd } from "@angular/router";
import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import _filter from "lodash/filter";
import _forEach from "lodash/forEach";
import { MsalService } from "@azure/msal-angular";
import { LoadingService } from "./core/services/loading.service";
import { delay } from "rxjs/operators";

@Component({
  selector: "cm-app-component",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent implements OnInit {
  queryParamsSubscription: Subscription;
  loading: boolean = false;

  constructor(
    private authService: MsalService,
    private router: Router,
    private route: ActivatedRoute,
    private planningDataService: PlanningDataService,
    private loadingService: LoadingService
  ) {}

  ngOnInit() {
    // Scrolling to the top when pages change
    this.listenToLoading();
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0);
    });
  }

  listenToLoading(): void {
    this.loadingService.loadingSub
      .pipe(delay(0)) // This prevents a ExpressionChangedAfterItHasBeenCheckedError for subsequent requests
      .subscribe((loading) => {
        this.loading = !!loading;
      });
  }

  ngOnDestroy() {
    this.queryParamsSubscription.unsubscribe();
  }

  login() {
    this.authService.loginRedirect();
  }
}
