export const LOCAL_STORAGE_KEYS = {
  VISIBILITY_MAP: "visibilityMap",
  ADDRESS_CENTER: "addressCenter",
  AREAS: "areas",
  SELECTED_AREAS: "selectedAreas",
  CLIENT: "client",
  USER: "currentUser",
  AREA_CORNERS: "areaCorners",
  NUM_PLACED_PANELS: "numPlacedPanels",
  USER_IMAGE_URL: "userImageUrl",
  USER_IMAGE_ANGLE: "userImageAngle",
  IMAGE_CORNERS: "imageCorners",
  PANELS_ORIENTATION: "panelsOrientation",
  GROUND_GRID: "groundGrid",
  GROUND_IMAGE_SCENE: "groundImageScene",
  SATELLITE_GRID: "satelliteGrid",
  SATELLITE_VIZ_SCENE: "satelliteVizScene",
  ROOF_PITCH: "roofPitch",
  PANEL_TYPE: "panelType",
  SHAREABLE_LINK: "shareableLink",
  SIDE_LENGTH_METERS: "sideLength",
  GROUND_IMAGE_AREAS: "groundImageAreas",
  AZIMUTH: "azimuth",
  INITIAL_AREAS_CORNERS: "initialAreasCorners",
  INITIAL_GROUND_CORNERS: "initialGroundCorners",
  INITIAL_SIDE_LENGTH: "initialSideLength",
};

export const ADDED_SPACE = 0.2;
export const PV_PANEL_TYPE = "PV_PANEL";
export const BATTERY_TYPE = "BATTERY";
export const ANCHOR_POINT_RADIUS = 1.5;
export const ANCHOR_POINT_HIT_RADIUS = 4;
export const CORNER_POINT_RADIUS = 3;
export const EVERY_X_PREDICTION_POINTS = 7;

export const PANEL_TYPES = [
  {
    id: "mono_black_frame",
    name: "Monokristallin – schwarzer Rahmen",
    panel: "../../assets/img/pv-panel-mono-black-with-frame-2.png",
    frame_rgb: [0, 0, 0],
    size: {
      width: 100,
      height: 170,
    },
  },
  {
    id: "full_black_black_frame",
    name: "Monokristallin – Full Black",
    panel: "../../assets/img/pv-panel-black.png",
    frame_rgb: [0, 0, 0],
    size: {
      width: 100, // just for future need
      height: 170, // just for future need
    },
  },
  {
    id: "mono_silver_frame",
    name: "Monokristallin – silberner Rahmen",
    panel: "../../assets/img/pv-panel-mono-black.png",
    frame_rgb: [169, 169, 169],
    size: {
      width: 100,
      height: 170,
    },
  },
  {
    id: "poly_silver_frame",
    name: "Polykristallin – silberner Rahmen",
    panel: "../../assets/img/pv-panel-poly-blue.png",
    frame_rgb: [169, 169, 169],
    size: {
      width: 100,
      height: 170,
    },
  },
];
