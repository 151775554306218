import { Component, Input, OnChanges } from "@angular/core";

import {
  Units,
  createUnitConverter,
  Orientation,
  toUnitString,
} from "../units";

const randOU = 0.15;
const hindernisse = 0.15;
const nachbar = 0.5;
const blitzschutz = 0.5;

@Component({
  styleUrls: ["./safety-distances-table.component.scss"],
  selector: "cm-safety-distances-table",
  templateUrl: "./safety-distances-table.component.html",
})
export class SafetyDistancesTableComponent implements OnChanges {
  @Input() sparrenBreite: number;
  @Input() sparrenAbstand: number;
  @Input() deckBreite: number;
  @Input() deckLaenge: number;

  @Input() unit: Units;

  randHorizontal: number;
  randVertikal: number;
  hindernisseHoehe: number;
  hindernisseBreite: number;
  nachbarhausHoehe: number;
  nachbarhausBreite: number;
  blitzschutzHoehe: number;
  blitzschutzBreite: number;

  unitString: string;

  ngOnChanges() {
    this.unitString = toUnitString(this.unit);

    const toTargetUnit = createUnitConverter(
      this.unit,
      this.deckBreite,
      this.deckLaenge
    );

    this.randHorizontal = toTargetUnit(
      calculateRandLR(this.sparrenAbstand, this.sparrenBreite),
      Orientation.Horizontal
    );
    this.randVertikal = toTargetUnit(randOU, Orientation.Vertical);
    this.hindernisseHoehe = toTargetUnit(hindernisse, Orientation.Vertical);
    this.hindernisseBreite = toTargetUnit(randOU, Orientation.Horizontal);
    this.nachbarhausHoehe = toTargetUnit(nachbar, Orientation.Vertical);
    this.nachbarhausBreite = toTargetUnit(nachbar, Orientation.Horizontal);
    this.blitzschutzHoehe = toTargetUnit(blitzschutz, Orientation.Vertical);
    this.blitzschutzBreite = toTargetUnit(blitzschutz, Orientation.Horizontal);
  }
}

function calculateRandLR(sparrenAbstand: number, sparrenBreite: number) {
  const s = sparrenAbstand + 1.5 * sparrenBreite;
  if (s <= 55) {
    return 0.15;
  }

  return s / 100 - 0.4;
}
