import { JwtInterceptor } from "./interceptors/jwt.interceptor";
import { AuthService } from "./services/auth.service";
import { AuthInterceptor } from "./interceptors/auth.interceptor";
import { NgModule, Optional, SkipSelf } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";

import { GrowlerModule } from "./growler/growler.module";
import { ModalModule } from "./modal/modal.module";

import { EnsureModuleLoadedOnceGuard } from "./ensureModuleLoadedOnceGuard";

import {
  L10nConfig,
  L10nLoader,
  StorageStrategy,
  ProviderType,
} from "angular-l10n";

// Advanced initialization.
export function initL10n(l10nLoader: L10nLoader): Function {
  return () => l10nLoader.load();
}

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    HttpClientModule,
    GrowlerModule,
    ModalModule,
  ],
  exports: [GrowlerModule, RouterModule, HttpClientModule, ModalModule],
  declarations: [],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
  ], // these should be singletons
})
export class CoreModule extends EnsureModuleLoadedOnceGuard {
  //Ensure that CoreModule is only loaded into AppModule

  //Looks for the module in the parent injector to see if it's already been loaded (only want it loaded once)
  constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    super(parentModule);
  }
}
