import { Component, Input, OnChanges } from "@angular/core";

import roundUp from "../roundUp";
import {
  Units,
  createUnitConverter,
  Orientation,
  toUnitString,
} from "../units";
import { ModuleType } from "../moduleTypes";

const rowCount = 50;

type ModuleSpec = Pick<ModuleType, "length" | "width" | "mounting">;

@Component({
  styleUrls: ["./tile-calculation-table.component.scss"],
  selector: "cm-tile-calculation-table",
  templateUrl: "./tile-calculation-table.component.html",
})
export class TileCalculationTableComponent implements OnChanges {
  @Input() deckBreite: number;
  @Input() deckLaenge: number;
  @Input() unit: Units;
  @Input() module: ModuleType;

  measurements: any;
  unitString: string;

  ngOnChanges() {
    this.unitString = toUnitString(this.unit);

    const toTargetUnit = createUnitConverter(
      this.unit,
      this.deckBreite,
      this.deckLaenge
    );
    // convert from mm to m
    const moduleSpec = {
      length: this.module.length / 1000,
      width: this.module.width / 1000,
      mounting: this.module.mounting,
    };

    this.measurements = Array.from({ length: rowCount }).map((_, idx) => {
      const moduleCount = idx + 1;
      return {
        moduleCount,
        hochkantBreite: toTargetUnit(
          moduleCount * moduleSpec.width,
          Orientation.Horizontal
        ),
        hochkantHoehe: toTargetUnit(
          moduleCount * moduleSpec.length,
          Orientation.Vertical
        ),
        querBreite: toTargetUnit(
          moduleCount * moduleSpec.length,
          Orientation.Horizontal
        ),
        querHoehe: toTargetUnit(
          moduleCount * moduleSpec.width,
          Orientation.Vertical
        ),
        // Aufstaenderung seems to be always in meters.
        aufstaenderungBreite: calculateAufstaenderungBreite(
          moduleCount,
          moduleSpec
        ),
        aufstaenderungLaenge: calculateAufstaenderungLaenge(
          moduleCount,
          moduleSpec
        ),
      };
    });
  }
}

function calculateAufstaenderungBreite(
  moduleCount: number,
  moduleSpec: ModuleSpec
) {
  // Show only every second row for this measure.
  if (moduleCount % 2 === 1) {
    return null;
  }

  return roundUp(
    moduleSpec.mounting * moduleCount + 0.02 * (moduleCount / 2 - 1),
    2
  );
}

function calculateAufstaenderungLaenge(
  moduleCount: number,
  moduleSpec: ModuleSpec
) {
  return roundUp(moduleSpec.length * moduleCount, 2);
}
