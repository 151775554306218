import { PlanningDataService } from "./../core/services/planning-data.service";
import { FormControl } from "@angular/forms";
import { ICustomer } from "./../shared/interfaces";
import { Router } from "@angular/router";
import { Subscription } from "rxjs";
import { ActivatedRoute } from "@angular/router";
import {
  Component,
  ElementRef,
  NgZone,
  OnInit,
  ViewChild,
} from "@angular/core";
import _forEach from "lodash/forEach";

@Component({
  selector: "cm-load-config-page",
  templateUrl: "./load-config-page.html",
})
export class LoadConfigPage implements OnInit {
  configId: number;

  public searchControl: FormControl;

  infoMessage;

  @ViewChild("search")
  public searchElementRef: ElementRef;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private planningDataService: PlanningDataService,
    private ngZone: NgZone
  ) {}

  ngOnInit() {}

  ngOnDestroy() {}

  loadConfig(exampleId) {}

  onLoad() {}

  toHome() {}
}
